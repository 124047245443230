import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class ResolutionStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const resolution = new Resolution(data, this.root);
    this.data.set(resolution.id, resolution);
  }
  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const resolution = new Resolution(data, this.root);
      merge.set(resolution.id, resolution);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByActivityInstance(key.split(':')[1]);
    }));
  }

  fetchByActivityInstance(activityInstanceId) {
    const requestKey = `resolution:${activityInstanceId}`;
    if (this.requests.get(requestKey) === undefined && this.requests.get('all') === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('resolutions', { activity_instance_id: activityInstanceId }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByActivityInstanceCompleted(activityInstanceId) {
    const requestKey = `resolution:${activityInstanceId}`;
    return this.requests.get(requestKey) === 'complete';
  }

  fetchByCompetition(competitionId) {
    const requestKey = `resolution:${competitionId}`;
    if (this.requests.get(requestKey) === undefined && this.requests.get('all') === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('resolutions', { competition_id: competitionId }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByCompetitionCompleted(competitionId) {
    const requestKey = `resolution:${competitionId}`;
    return this.requests.get(requestKey) === 'complete';
  }

  resolvedForStudentResolution(userId, instanceId) {
    return _.find(
      Array.from(this.data.values()),
      resolution => resolution.user_id === Number(userId) &&
        resolution.activity_instance_id === Number(instanceId) &&
        resolution.type === "LOW_SCORE"
    );
  }

  resolvedForQuestion(questionId, instanceId) {
    return _.find(
      Array.from(this.data.values()),
      resolution => resolution.question_id === Number(questionId) &&
        resolution.activity_instance_id === Number(instanceId) &&
        resolution.type === "PROBLEMATIC_QUESTION"
    );
  }

  resolvedForQuestionInstances(questionId, instanceIds) {
    return _.find(
      Array.from(this.data.values()),
      resolution => resolution.question_id === Number(questionId) &&
        _.includes(instanceIds, resolution.activity_instance_id) &&
        resolution.type === "PROBLEMATIC_QUESTION"
    );
  }

  resolvedForFollowerCompetition(userId, competitionId) {
    return _.find(
      Array.from(this.data.values()),
      resolution => (
        resolution.type === "FOLLOWER_REPORT" &&
        resolution.competition_id === competitionId &&
        resolution.user_id === userId
      )
    );
  }

  resolvedForFollowerInstance(userId, instanceId) {
    return _.find(
      Array.from(this.data.values()),
      resolution => (
        resolution.user_id === Number(userId) &&
        resolution.activity_instance_id === instanceId &&
        resolution.type === "FOLLOWER_REPORT"
      )
    );
  }
}

decorate(ResolutionStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  reset: action,
  fetchAll: action,
  fetchByActivityInstance: action,
  fetchByCompetition: action,
  refetch: action
});

export class Resolution {
  constructor(data, root) {
    this._root = root;
    this.activity_instance_id = data.activity_instance_id;
    this.competition_id = data.competition_id;
    this.id = data.id;
    this.type = data.type;
    this.user_id = data.user_id;
    this.question_id = data.question_id;
    this.resolved = data.resolved;
  }

  get instance() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id);
  }
}
