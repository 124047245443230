import React from 'react';
import { BeatLoader } from 'react-spinners';

const LoadingSpinner = () => (
  <div className="loading-spinner">
    <BeatLoader
      color="#7ec682"
    />
  </div>
);

export default LoadingSpinner;
