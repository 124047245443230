import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';

export default class ActivityExecutionStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const activityExecution = new ActivityExecution(data, this.root);
    this.data.set(activityExecution.id, activityExecution);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const activityExecution = new ActivityExecution(data, this.root);
      merge.set(activityExecution.id, activityExecution);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('activity_executions').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByActivityInstance(id) {
    const requestKey = `activityInstance:${id}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('activity_executions', { activity_instance_id: id }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByActivityInstance(key.split(':')[1]);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByActivityInstanceCompleted(id) {
    const requestKey = `activityInstance:${id}`;
    return this.requests.get(requestKey) === 'complete';
  }

  executionsForActivityInstance(id) {
    return Array.from(this.data.values()).filter(execution => (
      execution.activity_instance_id === parseInt(id, 10)
    ));
  }

  forUser(userId) {
    return Array.from(this.data.values()).filter(execution => (
      execution.user_id === parseInt(userId, 10)
    ));
  }

  forInstanceAndUser(instanceId, userId) {
    return _.find(Array.from(this.data.values()), { activity_instance_id: parseInt(instanceId, 10), user_id: parseInt(userId, 10) });
  }
}

decorate(ActivityExecutionStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
  fetchByActivityInstance: action
});

export class ActivityExecution {
  constructor(data, root) {
    this._root = root;
    this.activity_instance_id = data.activity_instance_id;
    this.created_at = data.created_at;
    this.id = data.id;
    this.disabled = data.disabled;
    this.rank = data.rank;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get activityInstance() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id);
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }
}
