import { observable, action, computed, decorate } from 'mobx';

class CategoriesStore {
  currentCategoryId = 0;

  updateCategory = (id) => {
    this.currentCategoryId = id;
  }
}

decorate(CategoriesStore, {
  currentCategoryId: observable,
  updateCategory: action
});

export default new CategoriesStore();
