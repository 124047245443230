import { observable, action, decorate } from 'mobx';

class PresenceStore {
  users = {};

  set(id, path) {
    this.users[id] = path;
  }

  handleDiff({ leaves, joins }) {
    const users = { ...this.users };
    const joinIds = Object.keys(joins);
    joinIds.forEach(join => {
      users[join] = joins[join].metas[0].path;
      delete leaves[join];
    });
    Object.keys(leaves).forEach(leave => {
      delete users[leave];
    });
    console.log('users in presence store', users);
    this.users = users;
  }
}

decorate(PresenceStore, {
  users: observable,
  set: action,
  handleDiff: action
});

export default new PresenceStore();
