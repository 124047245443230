import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class PlanPurchaseStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const planPurchase = new PlanPurchase(data, this.root);
    this.data.set(planPurchase.id, planPurchase);
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const planPurchase = new PlanPurchase(data, this.root);
      merge.set(planPurchase.id, planPurchase);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  get allOrderedByDate() {
    return _.sortBy(this.all, 'created_at').reverse();
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('/plan_purchases').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchAllByParent(parentId) {
    if (this.requests.get(`parent:${parentId}`) === undefined) {
      this.requests.set(`parent:${parentId}`, 'pending');
      return apiActions
        .rawGet(`parents/${parentId}/plan_purchases`)
        .then(response => {
          this.addMany(response.data);
          this.requests.set(`parent:${parentId}`, 'complete');
        });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  refetchByParent(parentId) {
    if (this.requests.get(`parent:${parentId}`) !== undefined) {
      this.requests.set(`parent:${parentId}`, undefined);
    }
    return this.fetchAllByParent(parentId);
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchAllByParentCompleted(parentId) {
    return this.requests.get(`parent:${parentId}`) === 'complete';
  }

  creditsPurchasedForParent(parentId, parentEmail) {
    return Array.from(this.data.values())
      .filter(
        purchase =>
          purchase.parent_id === parentId ||
          (parentEmail && purchase.parent_email === parentEmail),
      )
      .filter(purchase => purchase.plan)
      .map(purchase => purchase.plan.credits)
      .reduce((acc, credits) => acc + credits, 0);
  }

  purchasesForPlan(planId) {
    return this.all.filter(purchase => purchase.plan_id === planId);
  }

  purchasesWithSchedulesForParent({ id, email }) {
    return _.chain(Array.from(this.data.values()))
      .filter(
        purchase =>
          purchase.parent_id === id || purchase.parent_email === email,
      )
      .filter(
        purchase =>
          purchase.selected_options &&
          _.keys(purchase.selected_options).length > 0,
      )
      .filter(purchase => purchase.plan && purchase.plan.options)
      .filter(purchase =>
        _.some(
          purchase.selected_options,
          (value, key) =>
            _.find(_.find(purchase.plan.options, ['id', key]).choices, [
              'id',
              value,
            ]).schedule,
        ),
      )
      .value();
  }

  purchasesForParent({ id, email }) {
    return this.all.filter(
      purchase => purchase.parent_id === id || purchase.parent_email === email,
    );
  }

  /**
   * @deprecated in favor of the renamed `creditsPurchasedForParentInULC`.
   */
  hoursPurchasedForParentInUlc(parentId, parentEmail, learningCenterId) {
    return this.creditsPurchasedForParentInULC(
      parentId,
      parentEmail,
      learningCenterId,
    );
  }

  creditsPurchasedForParentInUlc(parentId, parentEmail, learningCenterId) {
    return Array.from(this.data.values())
      .filter(
        purchase =>
          (purchase.parent_id === parentId ||
            (parentEmail && purchase.parent_email === parentEmail)) &&
          Number(purchase.learning_center_id) === Number(learningCenterId),
      )
      .filter(purchase => purchase.plan)
      .map(purchase => purchase.plan.credits)
      .reduce((acc, hours) => acc + hours, 0);
  }
}

decorate(PlanPurchaseStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fechAllByParent: action,
  refetch: action,
  refetchByParent: action,
  fetchAllCompleted: computed,
});

export class PlanPurchase {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.created_at = data.created_at;
    this.discount_code = data.discount_code;
    this.discount_percentage = data.discount_percentage;
    this.learning_center_id = data.learning_center_id;
    this.parent_email = data.parent_email;
    this.parent_id = data.parent_id;
    this.plan_id = data.plan_id;
    this.price_paid = data.price_paid;
    this.selected_options = data.selected_options;
    this.user_id = data.user_id;
    this.potential_child_id = data.potential_child_id;
  }

  get plan() {
    return this._root.PlanStore.find(this.plan_id);
  }

  get parent() {
    return this._root.ParentStore.parentForPurchase(this);
  }
}
