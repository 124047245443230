import { observable, action, computed, decorate } from 'mobx';
import apiActions from 'api/actions';

export default class TrackActivityInstanceStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const trackActivityInstance = new TrackActivityInstance(data, this.root);
    this.data.set(trackActivityInstance.id, trackActivityInstance);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const trackActivityInstance = new TrackActivityInstance(data, this.root);
      merge.set(trackActivityInstance.id, trackActivityInstance);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('track_activity_instances').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchByTrackInstance(id) {
    const requestKey = `trackInstance:${id}`;
    if (this.requests.get('all') === undefined && this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('track_activity_instances', { track_instance_id: id }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByTrackInstance(key.split(':')[1]);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByTrackInstanceCompleted(id) {
    return this.fetchAllCompleted || this.requests.get(`trackInstance:${id}`) === 'complete';
  }

  forTrackInstance(trackInstanceId) {
    return Array.from(this.data.values()).filter(trackActivityInstance => (
      trackActivityInstance.track_instance_id === trackInstanceId
    ));
  }
}

decorate(TrackActivityInstanceStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
  fetchByTrackInstance: action
});

export class TrackActivityInstance {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.pass_percentage = data.pass_percentage;
    this.track_instance_id = data.track_instance_id;
    this.activity_instance_id = data.activity_instance_id;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
  }

  get activityId() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id).activity_id;
  }

  get activity() {
    return this._root.ActivityStore.find(this.activityId);
  }

  get activityInstance() {
    return this._root.ActivityInstanceStore.find(this.activity_instance_id);
  }

  get trackInstance() {
    return this._root.TrackInstanceStore.find(this.track_instance_id);
  }

  get passPercentage() {
    return this.pass_percentage ?
      this.pass_percentage :
      this.trackInstance.track.default_pass_percentage;
  }

  score(userId) {
    return this.activityInstance.trackFinalScore(userId);
  }

  scorePercent(userId) {
    return ((this.activityInstance.trackFinalScore(userId) / this.activity.totalPoints) * 100).toFixed(1);
  }

  userPassed(userId) {
    return Number(this.scorePercent(userId)) >= this.passPercentage;
  }

  get complete() {
    return Boolean(this._root.ActivityInstanceStore.find(this.activity_instance_id).completed_at);
  }
}
