export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Get date's offset from UTC in seconds.
 *
 * The javascript Date.prototype.getTimezoneOffset() function returns minutes
 * from local time to UTC, but the API expects seconds from UTC to local time.
 *
 * That's why it's (* -60)
 **/
export function getUTCOffset(date) {
  return date.toDate().getTimezoneOffset() * -60;
}

/**
 * Get the short (3 letter) name for the day represented by the number given.
 *
 * ISO day of week is defined as starting at 1 (Monday) and ending on 7 (Sunday).
 *
 * @param {number} ISODayOfWeek - The day of week, represented as an integer 1 - 7.
 * @returns {string} - The 3 letter common representation for the day's name.
 */
export function ISODayToStringDay(ISODayOfWeek) {
  const days = [null, "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
  return days[ISODayOfWeek];
}

/**
 * Get the lowercase string name for the date indicated by the integer.
 */
export function intToDay(integerDay) {
  const days = [
    "sunday",
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
  ];
  return days[integerDay];
}
