import React, { Component } from 'react';
import * as Sentry from '@sentry/browser';
import style from './styles.module.scss';

export default class ErrorBoundary extends Component {
  state = { error: null }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
    alert(error);
  }

  render() {
    if (this.state.error) {
      return (
        <div className="d-flex justify-content-center align-items-center p-2">
          <div className="d-flex flex-column">
            <p className="text-center">Oh no! It looks like you've encountered a bug!</p>
            <p className={`text-center ${style.refresh}`}>
              <strong>Please refresh your browser window and try again.</strong>
              <button onClick={() => window.location.reload()} className="btn btn-lg btn-primary">Click Here To Refresh</button>
            </p>
            <p className="text-center">The Unyted Team has already been notified.</p>
            <p className="text-center">If you'd like to provide more information that can help us replicate the bug, please report feedback using the button below.</p>
            <button className="btn btn-primary" onClick={() => Sentry.showReportDialog()}>Report Feedback</button>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}
