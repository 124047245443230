import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class LearningCenterStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const learningCenter = new LearningCenter(data, this.root);
    this.data.set(learningCenter.id, learningCenter);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const learningCenter = new LearningCenter(data, this.root);
      merge.set(learningCenter.id, learningCenter);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.v2RawGet('/users/me/learning_centers').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(LearningCenterStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchAllCompleted: computed,
  refetch: action,
});

export class LearningCenter {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.active = data.active;
    this.title = data.title;
    this.description = data.description;
    this.image_file_name = data.image_file_name;
    this.instructor_credentials = data.instructor_credentials;
    this.instructor_first_name = data.instructor_first_name;
    this.instructor_last_name = data.instructor_last_name;
    this.instructor_title = data.instructor_title;
    this.instructor_quote = data.instructor_quote;
    this.interest = data.interest;
    this.location = data.location;
    this.subjects = data.subjects;
    this.credits_remaining = data.credits_remaining;
  }
}
