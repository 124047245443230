let apiUrl;
let intercomId;

if (process.env.REACT_APP_API_ENV === 'production') {
  apiUrl = 'https://api.unyted.co';
  intercomId = 'v5bdmu7v';
} else if (process.env.REACT_APP_API_ENV === 'staging') {
  apiUrl = 'https://api-staging.unyted.co';
  intercomId = 'frna39ld';
} else {
  apiUrl = 'https://api-staging.unyted.co';
  intercomId = 'frna39ld';
}

exports.apiUrl = apiUrl;
exports.apiBase = apiUrl.split('//')[1];
exports.apiPrefix = apiUrl.split('//')[0];
exports.intercomId = intercomId;
