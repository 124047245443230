import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from '../../api/actions';

export default class CompetitionTeamStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const competitionTeam = new CompetitionTeam(data, this.root);
    this.data.set(competitionTeam.id, competitionTeam);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const competitionTeam = new CompetitionTeam(data, this.root);
      merge.set(competitionTeam.id, competitionTeam);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('competition_teams').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('competition_teams', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  fetchByCompetition(competitionId) {
    const requestKey = `competition:${competitionId}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('competition_teams', { competition_id: competitionId }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
        return this.teamsForCompetition(competitionId);
      });
    }
    return Promise.resolve(this.teamsForCompetition(competitionId));
  }

  refetch() {
    const competitionKey = RegExp('competition:*');
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      if (competitionKey.test(key)) {
        return this.fetchByCompetition(key.split(':')[1]);
      }
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }

  fetchByCompetitionCompleted(competitionId) {
    return this.requests.get(`competition:${competitionId}`) === 'complete';
  }

  teamsForCompetition(competitionId) {
    const id = parseInt(competitionId, 10);
    return Array.from(this.data.values()).filter((team) => {
      return team.competition_id === id;
    });
  }

  teamsForUser(userId) {
    const id = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(team => team.user_ids.includes(userId));
  }
}

decorate(CompetitionTeamStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  reset: action,
  fetchAll: action,
  fetchById: action,
  fetchByCompetition: action,
  refetch: action,
  fetchAllCompleted: computed
});

export class CompetitionTeam {
  constructor(data, root) {
    this._root = root;
    this.competition_id = data.competition_id;
    this.created_at = data.created_at;
    this.id = data.id;
    this.inactive_user_ids = data.inactive_user_ids || [];
    this.name = data.name;
    this.updated_at = data.updated_at;
    this.user_ids = data.user_ids;
  }

  get competition() {
    return this._root.CompetitionStore.find(this.competition_id);
  }

  get users() {
    return this.user_ids.map((id) => this._root.UserStore.find(id));
  }

  get activeUsers() {
    if (this.inactive_user_ids) {
      return this.user_ids.filter((id) => !this.inactive_user_ids.includes(id)).map((id) => this._root.UserStore.find(id));
    }
    return this.users;
  }

  get activityInstances() {
    const instances = this._root.ActivityInstanceStore.instancesForCompetitionTeam(this.id);
    return _.compact(this.competition.activity_ids.map(activityId => _.find(instances, ['activity_id', activityId])));
  }

  get totalPoints() {
    return this.activityInstances.reduce((acc, instance) => acc + instance.totalPoints, 0);
  }

  get ranking() {
    const teams = this.competition.competitionTeams;
    const ranked = _.reverse(_.sortBy(teams, 'totalPoints'));
    const rank = _.findIndex(ranked, ['totalPoints', this.totalPoints]) + 1;
    const isTied = _.filter(ranked, ['totalPoints', this.totalPoints]).length > 1;
    if (rank === 1) return `1st ${isTied ? '(Tied)' : ''}`;
    if (rank === 2) return `2nd ${isTied ? '(Tied)' : ''}`;
    if (rank === 3) return `3rd ${isTied ? '(Tied)' : ''}`;
    return rank + `th ${isTied ? '(Tied)' : ''}`;
  }

  get completedInstances() {
    return this.activityInstances.filter((instance) => instance.completionDate);
  }
}
