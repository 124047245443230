// Temporary hack until all images are migrated to the new system
import _ from 'lodash';
import missingUser from 'assets/img/shared/missing.png';

const missingKey = {
  users: missingUser
};

const bucket = process.env.NODE_ENV === 'production' ? 'unyted' : 'unyted-dev';

export default (resource, resourceName = '') => {
  const path = resource.image_file_name;
  if (!path) return missingKey[resourceName];
  if (path.match(/^http/)) return path;
  let idPartition = '000000000' + resource.id;
  idPartition = idPartition.substr(idPartition.length - 9, 9);
  idPartition = idPartition.split('').reverse().join('').replace(/(\d\d\d)/g, '$1/').split('').reverse().join('');
  return `https://s3.amazonaws.com/${bucket}/${resourceName}/images${idPartition}/original/${path}`;
};
