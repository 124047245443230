import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

const iconMatchRegex = /fa-(?<icon>[\w-]+)/;

export class Subject {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.icon = data.icon;
    this.domain_ids = data.domain_ids;
  }

  get domains() {
    return _.chain(this.domain_ids)
      .map(id => this._root.DomainStore.find(id))
      .compact()
      .value();
  }

  get gradeLevels() {
    return _.chain(this.domains)
      .map('gradeLevel')
      .compact()
      .uniq()
      .sort()
      .value();
  }

  get domainNames() {
    return _.map(this.domains, 'name');
  }

  get iconName() {
    const [prefix, iconSubName] = this.icon.split(' ');
    const iconMatches = iconSubName.match(iconMatchRegex);
    if (prefix === 'fas') {
      return iconMatches.groups.icon;
    }
    return [prefix, iconMatches.groups.icon];
  }
}

export default class SubjectStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const subject = new Subject(data, this.root);
    this.data.set(subject.id, subject);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const subject = new Subject(data, this.root);
      merge.set(subject.id, subject);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('academics/subjects').then((response) => {
        this.addMany(response.data.subjects);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  forDomainId(domainId) {
    return this.all.filter(subject => _.includes(subject.domain_ids, domainId));
  }
}

decorate(SubjectStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});
