import { observable, action, decorate } from 'mobx';
import _ from 'lodash';

class FlashStore {
  constructor() {
    this.callbacks = [];
  }
  alerts = [];

  received = true;

  registerCallback (cb) {
    this.callbacks.push(cb);
  }

  deregisterCallback (cb) {
    this.callbacks = this.callbacks.filter(c => c !== cb);
  }
  emptyCallbacks () {
    this.callbacks = [];
  }

  generate(message, type, id) {
    if (!_.some(this.alerts, ['id', id])) {
      const newAlert = { message, type, id };
      this.alerts = [...this.alerts, newAlert];
      this.received = false;
      const func = () => this.received = true;
      setTimeout(func.bind(this), 200);
    }
  }

  dismiss() {
    this.alerts.shift();
    this.callbacks.forEach(cb => cb());
  }
}

decorate(FlashStore, {
  alerts: observable,
  received: observable,
  registerCallback: action,
  deregisterCallback: action,
  emptyCallbacks: action,
  generate: action,
  dismiss: action
});

export default new FlashStore();
