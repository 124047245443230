import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';
import imagePath from 'utils/imagePath';

export default class TrackInstanceStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const track = new TrackInstance(data, this.root);
    this.data.set(track.id, track);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const track = new TrackInstance(data, this.root);
      merge.set(track.id, track);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get byTrack() {
    return (id, userId) => (
      _.filter(
        Array.from(this.data.values()),
        ti => ti.track_id === Number(id) && ti.owner_id === userId
      )
    );
  }

  get forUser() {
    return (userId, orgId) => (
      _.filter(
        Array.from(this.data.values()),
        ti => ti.owner_id === userId && ti.organizationId === orgId
      )
    );
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('track_instances').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get('all') === undefined && this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('track_instances', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      this.requests.set(key, undefined);
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.fetchAllCompleted || this.requests.get(id) === 'complete';
  }
}

decorate(TrackInstanceStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
  byTrack: computed,
  forUser: computed,
  fetchById: action
});

export class TrackInstance {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.inserted_at = data.inserted_at;
    this.created_at = data.inserted_at;
    this.updated_at = data.updated_at;

    this.track_id = data.track_id;
    this.owner_id = data.owner_id;
    this.organization_id = data.organization_id;
    this.previous_version_id = data.previous_version_id;
  }

  get owner() {
    return this._root.UserStore.find(this.owner_id);
  }

  belongsToUser(user) {
    return this.owner.id === user.id;
  }

  get imagePath() {
    return imagePath(this, 'track_instances');
  }

  get trackActivityInstances() {
    return this._root.TrackActivityInstanceStore.forTrackInstance(this.id);
  }

  get track() {
    return this._root.TrackStore.find(this.track_id);
  }

  get completedCount() {
    return _.filter(this.track.activities, activity => {
      const finalInstance = this.mostRecentInstanceForActivity(activity);
      return finalInstance && finalInstance.complete;
    }).length;
  }

  get completedPercent() {
    return ((this.completedCount / this.track.activities.length) * 100).toFixed();
  }

  get completed() {
    return Number(this.completedPercent) >= 100;
  }

  trackActivityInstancesForActivity(activity) {
    return _.filter(this.trackActivityInstances, tai => tai.activityId === activity.id);
  }

  hasStartedActivity(activity) {
    return this.trackActivityInstancesForActivity(activity).length > 0;
  }

  hasInstanceInProgress(activity) {
    return _.some(this.trackActivityInstancesForActivity(activity), tai => !tai.complete);
  }

  hasCompletedAnInstance() {
    return _.some(this.mostRecentTrackActivities(), tai => tai.complete);
  }

  get instancesInProgressCount() {
    const inProgress = _.filter(this.track.activities, activity => this.hasInstanceInProgress(activity));
    return inProgress.length;
  }

  mostRecentInstanceForActivity(activity) {
    return _.last(
      _.sortBy(
        this.trackActivityInstancesForActivity(activity), ['activityInstance', 'completed_at']
      )
    );
  }

  mostRecentTrackActivities() {
    return _.map(this.track.activities, activity => {
      return this.mostRecentInstanceForActivity(activity);
    });
  }

  totalScorePercent(userId) {
    const userScore = _.reduce(this.mostRecentTrackActivities(), (total, trackActivityInstance) => {
      return trackActivityInstance ? total + trackActivityInstance.score(userId) : total;
    }, 0);

    const totalPoints = _.reduce(this.track.activities, (total, activity) => {
      const trackActivityInstance = this.mostRecentInstanceForActivity(activity);

      if (!trackActivityInstance) { return total; }
      if (!trackActivityInstance.complete) { return total; }
      if (activity.calculatedPreferences.individual_staging) {
        return total + (activity.totalPoints / trackActivityInstance.activityInstance.participatingUsers.length);
      }

      return total + activity.totalPoints;
    }, 0);

    return totalPoints === 0 ? 0 : Number(((userScore / totalPoints) * 100).toFixed());
  }

  get completionDate() {
    return _.last(
      _.sortBy(this.mostRecentTrackActivities(), tai => tai.activityInstance.completed_at)
    ).activityInstance.completed_at;
  }

  get firstIncompleteInstance() {
    return _.filter(this.mostRecentTrackActivities(), tai => !tai.complete);
  }
}
