import { observable, action, computed, decorate } from 'mobx';
import apiActions from 'api/actions';

export class Comment {
  constructor(data, root) {
    this.id = data.id;
    this.body = data.body;
    this.user_id = data.user_id;
    this.activity_instance_id = data.activity_instance_id;
  }
}

export default class CommentStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const comment = new Comment(data, this.root);
    this.data.set(comment.id, comment);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const comment = new Comment(data, this.root);
      merge.set(comment.id, comment);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  get mostRecentComment() {
    return this.all[this.all.length - 1];
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll(activityInstanceId) {
    this.reset();
    this.requests.set('all', 'pending');
    return apiActions.index('comments', { activity_instance_id: activityInstanceId }).then((response) => {
      this.addMany(response.data);
      this.requests.set('all', 'complete');
    });

    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(CommentStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  mostRecentComment: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});
