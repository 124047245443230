import dm from 'assets/img/report-card/decision_making_g.png';
import dmWhite from 'assets/img/report-card/decision-making-white.svg';
import rs from 'assets/img/report-card/relationship_skills_g.png';
import rsWhite from 'assets/img/report-card/relationship-skills-white.svg';
import sm from 'assets/img/report-card/self_assessment_g.png';
import smWhite from 'assets/img/report-card/self-assessment-white.svg';
import sea from 'assets/img/report-card/self_awareness_g.png';
import seaWhite from 'assets/img/report-card/self-awareness-white.svg';
import soa from 'assets/img/report-card/social_awareness_g.png';
import soaWhite from 'assets/img/report-card/social-awareness-white.svg';

const categories = {
  "Responsible Decision Making": {
    icon: dm,
    iconWhite: dmWhite,
    humanName: "Positive Choices",
    tip: "The ability to make positive choices about personal behavior and evaluate consequences as it relates to making progress on work. Teachers and parents should work with students to relate data specifically to the work they've done and scenarios experienced.",
    databaseName: "decision_making"
  },
  "Relationship Skills":  {
    icon: rs,
    iconWhite: rsWhite,
    humanName: "Working With Others",
    tip: "The ability to work well with others in diverse, dynamic settings and groups through communication, listening, challenging and resolving conflict. Teachers and parents should work with students to relate data specifically to the work they've done and scenarios experienced.",
    databaseName: "relationship_skills"
  },
  "Self Management": {
    icon: sm,
    iconWhite: smWhite,
    humanName: "Managing Self",
    tip: "The ability to manage yourself and work positively toward goals. Teachers and parents should work with students to relate data specifically to the work they've done and scenarios experienced.",
    databaseName: "self_management"
  },
  "Self Awareness": {
    icon: sea,
    iconWhite: seaWhite,
    humanName: "Knowing Self",
    tip: "The ability to know yourself and how your behavior is influenced. Teachers and parents should work with students to relate data specifically to the work they've done and scenarios experienced.",
    databaseName: "self_awareness"
  },
  "Social Awareness": {
    icon: soa,
    iconWhite: soaWhite,
    humanName: "Aware Of Others",
    tip: "The ability to use perspective and understand others as it relates to working together toward goals. Teachers and parents should work with students to relate data specifically to the work they've done and scenarios experienced.",
    databaseName: "social_awareness"
  }
};

export default categories;
