import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Router } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import { createBrowserHistory } from 'history';
import { Provider } from 'mobx-react';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { addStyles as addMathquillStyles } from 'react-mathquill';
import ReactGA from 'react-ga';
import sessionStore from 'stores/session-store';
import categoriesStore from 'stores/library/categories-store';
import growlStore from 'stores/growl-store';
import flashStore from 'stores/flash-store';
import noticeStore from 'stores/notice-store';
import filterStore from 'stores/filter-store';
import presenceStore from 'stores/presence-store';
import spinnerStore from 'stores/spinner-store';
import confettiStore from 'stores/confetti-store';
import './avenir-font.css';
import './index.scss';
import App from './App';
import { unregister } from './registerServiceWorker';
import RootModelStore from './stores/root-model-store';
import actions from './api/actions';
import reportWebVitals from './reportWebVitals';

console.log('version', process.env.REACT_APP_VERSION);

Sentry.init({
  dsn: 'https://270c6338a9314fb9bc29151a0f2994c2@sentry.io/1359165',
  environment: process.env.REACT_APP_API_ENV,
  release: 'unyted-frontend@' + process.env.REACT_APP_VERSION,
  beforeSend(event, hint) {
    const error = hint.originalException;
    if (
      error &&
      error.message &&
      error.message === 'Request failed with status code 401'
    ) {
      console.log('not sending 401 spam');
      return null;
    }
    return event;
  },
});

// Polyfill for `Array.from()` for Internet Explorer
// Production steps of ECMA-262, Edition 6, 22.1.2.1
if (!Array.from) {
  Array.from = (function () {
    let toStr = Object.prototype.toString;
    let isCallable = function (fn) {
      return typeof fn === 'function' || toStr.call(fn) === '[object Function]';
    };
    let toInteger = function (value) {
      let number = Number(value);
      if (isNaN(number)) {
        return 0;
      }
      if (number === 0 || !isFinite(number)) {
        return number;
      }
      return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
    };
    let maxSafeInteger = Math.pow(2, 53) - 1;
    let toLength = function (value) {
      let len = toInteger(value);
      return Math.min(Math.max(len, 0), maxSafeInteger);
    };

    // The length property of the from method is 1.
    return function from(arrayLike /*, mapFn, thisArg */) {
      // 1. Let C be the this value.
      let C = this;

      // 2. Let items be ToObject(arrayLike).
      let items = Object(arrayLike);

      // 3. ReturnIfAbrupt(items).
      if (arrayLike === null) {
        throw new TypeError(
          'Array.from requires an array-like object - not null or undefined',
        );
      }

      // 4. If mapfn is undefined, then let mapping be false.
      let mapFn = arguments.length > 1 ? arguments[1] : void undefined;
      let T;
      if (typeof mapFn !== 'undefined') {
        // 5. else
        // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
        if (!isCallable(mapFn)) {
          throw new TypeError(
            'Array.from: when provided, the second argument must be a function',
          );
        }

        // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
        if (arguments.length > 2) {
          T = arguments[2];
        }
      }

      // 10. Let lenValue be Get(items, "length").
      // 11. Let len be ToLength(lenValue).
      let len = toLength(items.length);

      // 13. If IsConstructor(C) is true, then
      // 13. a. Let A be the result of calling the [[Construct]] internal method
      // of C with an argument list containing the single item len.
      // 14. a. Else, Let A be ArrayCreate(len).
      let A = isCallable(C) ? Object(new C(len)) : new Array(len);

      // 16. Let k be 0.
      let k = 0;
      // 17. Repeat, while k < len… (also steps a - h)
      let kValue;
      while (k < len) {
        kValue = items[k];
        if (mapFn) {
          A[k] =
            typeof T === 'undefined'
              ? mapFn(kValue, k)
              : mapFn.call(T, kValue, k);
        } else {
          A[k] = kValue;
        }
        k += 1;
      }
      // 18. Let putStatus be Put(A, "length", len, true).
      A.length = len;
      // 20. Return A.
      return A;
    };
  })();
}

let hidden, visibilityChange;
if (typeof document.hidden !== 'undefined') {
  hidden = 'hidden';
  visibilityChange = 'visibilityChange';
} else if (typeof document.msHidden !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document.webkitHidden !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}
const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

window.addEventListener(
  visibilityChange,
  () => {
    if (
      sessionStore.currentUser &&
      sessionStore.token &&
      iOS &&
      !document[hidden]
    ) {
      if (
        actions &&
        actions.conn &&
        actions.conn.listener &&
        actions.conn.listener.channel
      ) {
        actions.conn.listener.fetchPausedMessages();
      }
      Object.values(RootModelStore.stores).forEach(store => {
        store.refetch();
      });
    }
  },
  false,
);

addMathquillStyles();

const browserHistory = createBrowserHistory();
const routingStore = new RouterStore();

RootModelStore.setSessionStore(sessionStore);

const stores = {
  routing: routingStore,
  sessionStore,
  categoriesStore,
  growlStore,
  flashStore,
  noticeStore,
  filterStore,
  presenceStore,
  spinnerStore,
  confettiStore,
  ...RootModelStore.stores,
};

const history = syncHistoryWithStore(browserHistory, routingStore);

ReactGA.initialize('UA-134689128-1', {
  gaOptions: { siteSpeedSamplingRate: 10 },
});
ReactGA.set({ anonymizeIp: true });
history.subscribe(location => ReactGA.pageview(location.pathname));
function sendToAnalytics({ id, name, value }) {
  ReactGA.event({
    category: 'Web Vitals',
    action: name,
    value: Math.round(name === 'CLS' ? value * 1000 : value),
    label: id,
    nonInteraction: true,
  });
}
reportWebVitals(sendToAnalytics);

const AppWithRoutes = () => (
  <Provider {...stores}>
    <Router history={history}>
      <CookiesProvider>
        <App />
        {/* {DevTools && <DevTools />} */}
      </CookiesProvider>
    </Router>
  </Provider>
);

ReactDOM.render(<AppWithRoutes />, document.getElementById('root'));
unregister();
