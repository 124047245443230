import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import { Activity } from './activity-store';
import apiActions from 'api/actions';
import imagePath from 'utils/imagePath';

export default class TrackStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const track = new Track(data, this.root);
    this.data.set(track.id, track);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const track = new Track(data, this.root);
      merge.set(track.id, track);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('tracks').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get('all') === undefined && this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('tracks', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.fetchAllCompleted || this.requests.get(id) === 'complete';
  }

  tracksIncludingActivity(activityId) {
    return _.filter(Array.from(this.data.values()), track => {
      return _.some(track.activities, { id: activityId });
    });
  }

  differentiationTracksFor(category) {
    return _.filter(this.all, track =>
      _.includes(track.sel_differentiation_category, category)
    );
  }
}

decorate(TrackStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchById: action,
  refetch: action,
  fetchAllCompleted: computed
});

export class Track {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.title = data.title;
    this.description = data.description;
    this.default_pass_percentage = data.default_pass_percentage;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;

    this.image = data.image;
    this.image_content_type = data.image_content_type;
    this.image_file_name = data.image_file_name;
    this.image_file_size = data.image_file_size;

    this.sel_differentiation_category = data.sel_differentiation_category;
    this.sel_weight = data.sel_weight;
    this.created_by_id = data.created_by_id;
    this.organization_id = data.organization_id;
    this.previous_version_id = data.previous_version_id;
    this.previewActivities = data.preview_activities && data.preview_activities.map(pa => new Activity(pa));
    /*
      This process seems kind of backwards, but I'm not sure of the
      implications with websockets

      In most other models, we load the ids of an association, and then create
      a getter to retrieve
      the most recent data from the store.

      Is this necessary at this point? I can load the associated objects from
      the backend with ecto instead but I'm wondering if the new websocket
      framework supports this pattern
    */
    this.track_activity_ids = data.track_activities.map(ta => ta.id);
  }

  get imagePath() {
    return imagePath(this, 'tracks');
  }

  get trackActivities() {
    return _.map(this.track_activity_ids, taId => this._root.TrackActivityStore.find(taId));
  }

  get activities() {
    return this.previewActivities ?
      this.previewActivities :
      _.map(
        _.sortBy(this.trackActivities, ta => ta.order_index),
        (ta) => ta.activity
      );
  }

  get totalTimeMinutes() {
    return _.reduce(this.activities, (total, activity) => {
      return total + activity.totalTimeMinutes;
    }, 0);
  }
}
