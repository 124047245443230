import { observable, action, decorate } from 'mobx';

class FilterStore {
  searches = {};

  set (name, value) {
    this.searches = { ...this.searches, [name]: value };
  }
}

decorate(FilterStore, {
  searches: observable,
  set: action
});

export default new FilterStore();
