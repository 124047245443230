// Copied over from parent app and modified.
import moment from 'moment';

/**
 * Check if the token is expired.
 *
 * Does not verify token, it simply reads it.
 * @param {object} token - The token to check.
 * @return {boolean} - Whether or not the token is expired.
 */
export function tokenExpired(token) {
  try {
    const payload = JSON.parse(atob(token.split('.')[1]));
    const expires = moment.unix(payload.exp);
    return moment().isAfter(expires);
  } catch (e) {
    // console.error(e);
    if (e.message === 'Token has expired') return true;
    throw e;
  }
}

