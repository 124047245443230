import { observable, action, decorate } from 'mobx';

class NoticeStore {
  notices = [];

  generate({ message, heading }, callback = () => {}) {
    const newNotice = { message, heading, callback };
    this.notices.unshift(newNotice);
  }

  dismiss() {
    this.notices.shift().callback();
  }
}

decorate(NoticeStore, {
  notices: observable,
  generate: action,
  dismiss: action
});

export default new NoticeStore();
