import { observable, action, computed, decorate } from 'mobx';
import apiActions from '../../api/actions';

export default class MembershipStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const membership = new Membership(data, this.root);
    this.data.set(membership.id, membership);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const membership = new Membership(data, this.root);
      merge.set(membership.id, membership);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('memberships').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  forUser(userId) {
    return Array.from(this.data.values()).filter(m => m.user_id === userId);
  }

  forOrganization(orgId) {
    return Array.from(this.data.values()).filter(m => m.organization_id === orgId);
  }

  forUserAndOrganization(userId, orgId) {
    return Array.from(this.data.values()).filter(membership => {
      return membership.user_id === userId && membership.organization_id === orgId;
    });
  }
}

decorate(MembershipStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
  length: computed
});

export class Membership {
  constructor(data, root) {
    this._root = root;
    this.active = data.active;
    this.attestation_history = data.attestation_history;
    this.attestation_intervals = data.attestation_intervals;
    this.authorization = data.authorization;
    this.created_by = data.created_by;
    this.default_team_id = data.default_team_id;
    this.id = data.id;
    this.organization_id = data.organization_id;
    this.should_attest = data.should_attest;
    this.updated_at = data.updated_at;
    this.user_id = data.user_id;
  }

  get user() {
    return this._root.UserStore.find(this.user_id);
  }
}
