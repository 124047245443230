import { observable, action, decorate } from 'mobx';
import _ from 'lodash';

class GrowlStore {
  alerts = [];
  generate({ message, type = 'success', headline = '', id }) {
    if (!_.some(this.alerts, ['id', id])) {
      const newAlert = {
        id,
        type,
        headline,
        message
      };
      this.alerts = [...this.alerts, newAlert];
    }
  }

  pop(alert) {
    // find the index of the alert that was dismissed
    const { alerts } = this;
    const idx = alerts.indexOf(alert);

    if (idx >= 0) {
      // remove the alert from the array
      this.alerts = [...alerts.slice(0, idx), ...alerts.slice(idx + 1)];
    }
  }
}

decorate(GrowlStore, {
  alerts: observable,
  generate: action,
  pop: action
});

export default new GrowlStore();
