import { observable, action, computed, decorate } from 'mobx';
import apiActions from 'api/actions';

export default class ChildImageOptionStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const childImageOption = new ChildImageOption(data, this.root);
    this.data.set(childImageOption.id, childImageOption);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const childImageOption = new ChildImageOption(data, this.root);
      merge.set(childImageOption.id, childImageOption);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.v2RawGet('child_image_options').then(response => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(ChildImageOptionStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchAllCompleted: computed,
  refetch: action,
});

export class ChildImageOption {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.active = data.active;
    this.base_image_file_name = data.base_image_file_name;
    this.reinforcement_image_file_name = data.reinforcement_image_file_name;
  }
}
