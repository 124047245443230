import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export class Category {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.machine_name = data.machine_name;
    this.description = data.description;
    this.image_file_name = data.image_file_name;
  }

  get powerSkills() {
    return this._root.PowerSkillStore.powerSkillsForCategory(this.id);
  }
}

export class PowerSkill {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.machine_name = data.machine_name;
    this.category_id = data.category_id;
    this.description = data.description;
    this.how_we_do_it = data.how_we_do_it;
    this.real_world_application = data.real_world_application;
    this.image_file_name = data.image_file_name;
    this.video_url = data.video_url;
  }

  get category() {
    return this._root.PowerSkillStore.findCategory(this.category_id);
  }

  get levels() {
    return this._root.PowerSkillStore.levelsForPowerSkill(this.id);
  }
}

export class Level {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.level = data.level;
    this.power_skill_id = data.power_skill_id;
    this.description = data.description;
    this.teacher_description = data.teacher_description;
    this.parent_description = data.parent_description;
    this.image_file_name = data.image_file_name;
    this.video_url = data.video_url;
    this.url_resources = data.url_resources;
    this._parent_share_title = data.parent_share_title;
    this._parent_share_link = data.parent_share_link;
    this._parent_share_quote = data.parent_share_quote;
    this._parent_share_tags = data.parent_share_tags || [];
  }

  get powerSkill() {
    return this._root.PowerSkillStore.findPowerSkill(this.power_skill_id);
  }

  get machine_name() {
    return `${this.powerSkill.machine_name}_level_${this.level === 'Master' ? 6 : parseInt(this.level, 10)}`;
  }

  get parent_share_title() {
    return this._parent_share_title || `Practicing ${this.powerSkill.name}`;
  }

  get parent_share_link() {
    return this._parent_share_link || `https://unyted.co/sel-encyclopedia/competencies/${_.kebabCase(this.powerSkill.category.machine_name)}/${_.kebabCase(this.powerSkill.machine_name)}`;
  }

  get parent_share_quote() {
    return this._parent_share_quote || this.parent_share_title;
  }

  get parent_share_tags() {
    return this._parent_share_tags.length > 0 ? this._parent_share_tags : ['unyted', 'sel'];
  }

  get journalEntries() {
    return this._root.JournalEntryStore.forPowerSkillLevel(this.id);
  }
}

export default class PowerSkillStore {
  constructor(root) {
    this.root = root;
  }

  categories = new Map();
  powerSkills = new Map();
  levels = new Map();
  requests = new Map();

  add = data => {
    const category = new Category(data, this.root);
    this.categories.set(category.id, category);
    data.power_skills.each(dataPowerSkill => {
      const powerSkill = new PowerSkill(dataPowerSkill, this.root);
      this.powerSkills.set(powerSkill.id, powerSkill);
      dataPowerSkill.levels.each(dataLevel => {
        const level = new Level(dataLevel, this.root);
        this.levels.set(level.id, level);
      });
    });
  }

  addMany = datas => {
    const categoryMerge = new Map();
    const powerSkillMerge = new Map();
    const levelMerge = new Map();
    datas.forEach(data => {
      const category = new Category(data, this.root);
      categoryMerge.set(category.id, category);
      data.power_skills.forEach(dataPowerSkill => {
        const powerSkill = new PowerSkill(dataPowerSkill, this.root);
        powerSkillMerge.set(powerSkill.id, powerSkill);
        dataPowerSkill.levels.forEach(dataLevel => {
          const level = new Level(dataLevel, this.root);
          levelMerge.set(level.id, level);
        });
      });
    });
    this.categories.merge(categoryMerge);
    this.powerSkills.merge(powerSkillMerge);
    this.levels.merge(levelMerge);
  }

  findCategory(id) {
    return this.categories.get(id);
  }

  findPowerSkill(id) {
    return this.powerSkills.get(id);
  }

  findLevel(id) {
    return this.levels.get(id);
  }

  get allCategories() {
    return Array.from(this.categories.values());
  }

  get allPowerSkills() {
    return Array.from(this.powerSkills.values());
  }

  get allLevels() {
    return Array.from(this.levels.values());
  }

  reset = () => {
    this.categories.clear();
    this.powerSkills.clear();
    this.levels.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.get('power_skills').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  powerSkillsForCategory(id) {
    return this.allPowerSkills.filter(powerSkill => powerSkill.category_id === id);
  }

  levelsForPowerSkill(id) {
    return this.allLevels.filter(level => level.power_skill_id === id);
  }
}

decorate(PowerSkillStore, {
  categories: observable,
  powerSkills: observable,
  levels: observable,
  requests: observable,
  add: action,
  addMany: action,
  allCategories: computed,
  allPowerSkills: computed,
  allLevels: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});
