import _ from 'lodash';
import axios from 'axios';
import { apiUrl } from '../config/apiUrl';
import Connection from './Connection';
import sessionStore from '../stores/session-store';

_.mixin(require('lodash-inflection'));

const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

const resourcify = string => _.pluralize(_.snakeCase(string));

const resourceURL = (resourceName, resourceId = null) => {
  let URL = `${apiUrl}/api/${resourcify(resourceName)}`;
  if (resourceId) {
    URL = `${URL}/${resourceId}`;
  }
  return URL;
};


const actions = {
  login(login) {
    const data = { auth: login };
    // This could be simplified with a promise chain instead of an extra
    // promise wrapper.
    return new Promise((resolve, reject) => {
      axios.post(`${apiUrl}/sign_in`, data).then((r) => {
        resolve(r.data);
      }).catch(r => reject(r));
    });
  },

  refreshAuthToken(injectedRefreshToken) {
    return axios.post(`${apiUrl}/session/refresh`, { refresh: injectedRefreshToken })
      .then(response => {
        console.log('refreshed', response.data);
        return response.data;
      });
  },

  switchOrg(orgId) {
    const data = { new_organization: orgId };
    return axios.patch(`${apiUrl}/api/switch_organization`, data);
  },

  setupAuthenticated(sessionStore) {
    axios.defaults.headers.common.Authorization = sessionStore.token;
    if (this.conn) {
      this.conn.disconnect();
      delete this.conn;
    }
    this.conn = new Connection(sessionStore);

    window.addEventListener('focus', () => {
      if (iOS && this.conn) {
        this.conn.disconnect();
        this.conn.connect();
      }
    });
    axios.interceptors.request.use((config) => {
      if (this.conn === undefined) {
        // We havn't established a websocket connection yet.
        return config;
      } else if (this.conn.isConnected()) {
        // We have a connection and it's connected.
        return config;
      } else {
        // Websocket connection is defined, but not connect, so reconnect
        this.conn.connect();
        return config;
      }
    }, function (error) {
      return Promise.reject(error);
    });
  },

  teardownAuthenticated() {
    axios.defaults.headers.common.Authorization = null;
    if (this.conn) {
      this.conn.disconnect();
      delete this.conn;
    }
  },

  setupUserMessages(userMessageStore) {
    if (this.conn) {
      this.conn.connectUserMessages(userMessageStore);
    }
  },

  sendUserMessage(payload) {
    this.conn.sendUserMessage(payload);
  },

  markUserMessageRead(messageId) {
    this.conn.markUserMessageRead(messageId)
  },

  track(path) {
    if (!this.conn) return;
    this.conn.listener.track(path);
  },

  // NOTE: This functionality could probably be entirely handled by axios's interceptors.
  _wrap(promise) {
    promise.catch((e) => {
      console.log(e);
      if (e.response && e.response.status === 401) {
        if (this.conn) {
          this.conn.disconnect();
          delete this.conn;
        }
        axios.defaults.headers.common.Authorization = null;
        sessionStore.logout();
      }
    });
    return promise;
  },

  create(resourceName, values) {
    return actions._wrap(axios.post(resourceURL(resourceName), values));
  },

  update(resourceName, values) {
    return actions._wrap(axios.patch(resourceURL(resourceName, values.id), values));
  },

  get(resourceName, id) {
    return actions._wrap(axios.get(resourceURL(resourceName, id)));
  },

  index(resourceName, params) {
    return actions._wrap(axios.get(resourceURL(resourceName), { params }));
  },

  delete(resourceName, id) {
    return actions._wrap(axios.delete(resourceURL(resourceName, id)));
  },

  post(path, data) {
    return actions._wrap(axios.post(`${apiUrl}/api/${path}`, data));
  },

  rawGet(path, params) {
    return actions._wrap(axios.get(`${apiUrl}/api/${path}`, { params }));
  },

  v2RawGet(path, params) {
    return actions._wrap(axios.get(`${apiUrl}/v2/api/${path}`, { params }));
  },

  rawPost(path, data) {
    return actions._wrap(axios.post(`${apiUrl}/api/${path}`, data));
  },

  v2RawPost(path, data) {
    return actions._wrap(axios.post(`${apiUrl}/v2/api/${path}`, data));
  },

  rawPatch(path, data) {
    return actions._wrap(axios.patch(`${apiUrl}/api/${path}`, data));
  },

  v2RawPatch(path, data) {
    return actions._wrap(axios.patch(`${apiUrl}/v2/api/${path}`, data));;
  },

  rawDelete(path, id) {
    return actions._wrap(axios.delete(`${apiUrl}/api/${path}/${id}`));
  },

  v2RawDelete(path, id) {
    return actions._wrap(axios.delete(`${apiUrl}/v2/api/${path}/${id}`));;
  }
};

export default actions;
