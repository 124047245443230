// listens for Websocket messages and processes them
// to the appropriate Collection/action,
// and sends an ACK message back to the server

// constructor must be passed a Phoenix Channel
import _ from 'lodash';
import swal from 'sweetalert';
import * as Sentry from '@sentry/browser';
import growlStore from 'stores/growl-store';
import flashStore from 'stores/flash-store';
import presenceStore from 'stores/presence-store';
import RecentMessageStore from 'stores/recent-message-store';
import HelperModal from 'components/shared/helper-modal';
import RootModelStore from 'stores/root-model-store';
import SessionStore from 'stores/session-store';

const MessageTypes = {
  CREATE: 'INSERT',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
  GROWL: 'GROWL',
  FLASH: 'FLASH',
  HELPER_DIALOG: 'HELPER_DIALOG'
};

class WebSocketListener {
  constructor(channel, trackingChannel) {
    this.handleMessage = this.handleMessage.bind(this);
    this.handleClearedBuffer = this.handleClearedBuffer.bind(this);
    this.checkMessageId = this.checkMessageId.bind(this);
    this.handleNextMessageId = this.handleNextMessageId.bind(this);
    this.catchUp = this.catchUp.bind(this);
    this.handleCatchUpMessages = this.handleCatchUpMessages.bind(this);
    this.updateRecentMessageStore = this.updateRecentMessageStore.bind(this);
    this.warnUserAndTryRefresh = this.warnUserAndTryRefresh.bind(this);
    this.fetchPausedMessages = this.fetchPausedMessages.bind(this);

    this._channel = channel;
    this._trackingChannel = trackingChannel;
    this._channel.onError((error) => {
      console.error('MESSAGE channel error:', error);
      if (error) {
        Sentry.captureException(error);
      }
      this.warnUserAndTryRefresh();
    });
    this._channel.on('MESSAGE', this.handleMessage);
    this._channel.on('buffer_clear', this.handleClearedBuffer);
    if (this._trackingChannel) {
      this._trackingChannel.onError((error) => {
        console.error('presence channel error:', error);
        if (error) {
          Sentry.captureException(error);
        }
        this.warnUserAndTryRefresh();
      });
      this._trackingChannel.on('presence_diff', presenceStore.handleDiff.bind(presenceStore));
      this._trackingChannel.join();
    }
    this._channel.join()
      .receive('ok', () => {
        console.log('MESSAGE channel joined');
        this.checkMessageId();
      });
    this.checkMessageIdTimer = null;
    this.networkWarning = false;
  }

  track(path) {
    if (this._trackingChannel) {
      this._trackingChannel.push('track', path);
    }
  }

  handleMessage(msg) {
    console.log('incoming websocket message', msg);
    const { type, id, model, value } = msg;
    if (RootModelStore.modelsHandled.includes(model)) {
      RootModelStore.handleIncomingMessage(msg);
      this.updateRecentMessageStore(id);
    } else {
      switch (type) {
        case MessageTypes.FLASH:
          flashStore.generate(value, model, id);
          break;
        case MessageTypes.GROWL:
          growlStore.generate({ message: value, type: model, id });
          break;
        case MessageTypes.HELPER_DIALOG:
          HelperModal.activate(model, value);
          break;
        default:
      }
    }
  }

  handleClearedBuffer() {
    if (!RecentMessageStore.lastId) {
      // We can be sure that both the client and server (MessageHistoryBuffer)
      // have started at the same time. We're not out of sync.
      return;
    } else if (RecentMessageStore.lastId && !RecentMessageStore.catchingUp) {
      // The backend server crashed and we may be out of sync as a result. We
      // should probably prompt the user to refresh their page.
      this.warnUserAndTryRefresh();
    } else if (RecentMessageStore.lastId && RecentMessageStore.catchingUp) {
      // The backend server crashed while we were trying to catch up on missed
      // messages. We are DEFINITELY out of sync and should prompt for a
      // refresh.
      this.warnUserAndTryRefresh();
    }
  }

  checkMessageId() {
    this._channel.push('next_message_id', {})
      .receive('ok', this.handleNextMessageId);
  }

  handleNextMessageId({ next_message_id: id }) {
    if (!RecentMessageStore.catchingUp) {
      // We want to ignore this if we're currently catching up on missed
      // messages.
      if (!RecentMessageStore.lastId) {
        // Not having a `lastId` in the store means we just initialized, so we
        // need to set it in preparation for the next value.
        RecentMessageStore.lastId = id - 1;
      } else if (RecentMessageStore.lastId < id - 1) {
        // If our last tracked ID is different by more than 1, that means we
        // missed a message somewhere.
        Sentry.captureMessage('periodic websocket checker noticed a gap, gonna catch up', 'warning');
        this.catchUp();
      } else if (RecentMessageStore.lastId > id - 1) {
        // If our tracked ID s greater than what the server has, it must have
        // restart somewhere and we must have missed the `buffer_clear`
        // message. We may be out of sync. Either way, we need to set the
        // `lastId` so we can properly track future messages.
        RecentMessageStore.lastId = id - 1;
        this.warnUserAndTryRefresh();
      }
    }
    // In any case, we set a timer to check the next message ID again in 5
    // seconds. This means that `checkMessageId` and `handleNextMessageId`
    // should be constantly batting back and forth between the client and
    // server. This also means that about 5 seconds should be the maximum
    // amount of time for which a client will be out of sync.
    this.checkMessageIdTimer = setTimeout(this.checkMessageId, 5000);
  }

  catchUp() {
    RecentMessageStore.catchingUp = true;
    this._channel.push('get_since', RecentMessageStore.lastId)
      .receive('ok', this.handleCatchUpMessages);
  }

  handleCatchUpMessages({ messages }) {
    Sentry.withScope((scope) => {
      scope.setLevel('warning');
      scope.setFingerprint(['catchup-missed-websocket-messages']);
      scope.setExtra('messages.length', messages.length);
      Sentry.captureMessage(`catching up on missed websocket messages`);
    });

    const lastId = _.max(_.map(messages, 'id'));
    RecentMessageStore.lastId = lastId;
    RecentMessageStore.catchingUp = false;
    messages.forEach(RootModelStore.handleIncomingMessage);
  }

  fetchPausedMessages() {
    RecentMessageStore.catchingUp = true;
    this.channel.push('get_since', RecentMessageStore.lastId)
      .receive('ok', ({ messages }) => {
        const lastId = _.max(_.map(messages, 'id'));
        RecentMessageStore.lastId = lastId;
        RecentMessageStore.catchingUp = false;
        messages.forEach(RootModelStore.handleIncomingMessage);
      });
  }

  updateRecentMessageStore(id) {
    if (RecentMessageStore.lastId === id - 1) {
      RecentMessageStore.lastId = id;
    } else if (RecentMessageStore.lastId < id - 1) {
      this.catchUp();
    }
  }

  warnUserAndTryRefresh() {
    if (!this.networkWarning && SessionStore.token) {
      setTimeout(() => {
        if (SessionStore.token) {
          let content = document.createElement('div');
          content.innerHTML = `You've been disconnected! The best way to quickly reconnect is to <strong class="font-130">REFRESH</strong> your browser.`;

          swal({
            content,
            title: "Network Issues",
            icon: "warning"
          });
          this.networkWarning = true;
        }
      }, 1000);
    }
  }
}

export default WebSocketListener;
