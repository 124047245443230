import { observable, action, decorate } from 'mobx';

class ConfettiStore {
  active = false;

  throw() {
    this.active = true;
  }

  off() {
    this.active = false;
  }
}

decorate(ConfettiStore, {
  active: observable,
  throw: action.bound,
  off: action.bound
});

export default new ConfettiStore();
