import sessionStore from 'stores/session-store';
import actions from 'api/actions';

const returnLsKey = keyName => {
  const items = localStorage.getItem(keyName);
  if (!items) { return {}; }
  return JSON.parse(items);
};

export const getEvents = () => {
  return returnLsKey('helperEvents');
};

export const getReportCard = () => {
  return returnLsKey('reportCard');
};

export const hasNotRunForCurrentUrl = (eventName, data) => {
  const events = getEvents();
  const event = events[eventName];
  return (
    !event ||
    Boolean(
      event &&
      event.hasRunFor &&
      !event.hasRunFor.includes(data.match.url)
    )
  );
};

export const scopeToCurrentUrl = (eventName, data) => {
  let events = getEvents();
  let event = events[eventName];
  if (!event) {
    event = {};
  }

  if (event.hasRunFor) {
    event.hasRunFor.push(data.match.url);
  } else {
    event.hasRunFor = [];
    event.hasRunFor.push(data.match.url);
  }

  events[eventName] = event;
  localStorage.setItem('helperEvents', JSON.stringify(events));
};

export const logForReportCard = (eventName, data, identifier) => {
  if (!eventName || !identifier) {
    console.warn(
      `You've tried to log an event, and didn't supply the instance id or event name. InstanceId: ${identifier} Event: ${eventName}`
    );
    return;
  }

  actions.post('report_cards', {
    activity_instance_id: Number(identifier),
    user_id: sessionStore.currentUser.id,
    report_card: eventName
  })
    .then(r => {
      console.log('Success creating/updating report card', eventName);
    });
};
