import { observable, action, computed, decorate } from 'mobx';
import apiActions from 'api/actions';

export class Skill {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.name = data.name;
    this.parent_description = data.parent_description;
    this.video_url = data.video_url;
    this.standard_id = data.standard_id;
    this.parent_share_title = data.parent_share_title;
    this.parent_share_link = data.parent_share_link;
    this.parent_share_quote = data.parent_share_quote;
    this.parent_share_tags = data.parent_share_tags;
    this.url_resources = data.url_resources;
  }

  get standard() {
    return this._root.StandardStore.find(this.standard_id);
  }

  get domain() {
    return this.standard.domain;
  }

  get gradeLevel() {
    return this.domain.gradeLevel;
  }

  get subject() {
    return this.domain.subject;
  }

  get journalEntries() {
    return this._root.JournalEntryStore.forSkill(this.id);
  }
}

export default class SkillStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const skill = new Skill(data, this.root);
    this.data.set(skill.id, skill);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const skill = new Skill(data, this.root);
      merge.set(skill.id, skill);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('academics/skills').then((response) => {
        this.addMany(response.data.skills);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(SkillStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});
