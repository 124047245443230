import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class PotentialChildLearningCenterStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const potentialChildLearningCenter = new PotentialChildLearningCenter(
      data,
      this.root,
    );
    this.data.set(
      potentialChildLearningCenter.id,
      potentialChildLearningCenter,
    );
  };

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const potentialChildLearningCenter = new PotentialChildLearningCenter(
        data,
        this.root,
      );
      merge.set(potentialChildLearningCenter.id, potentialChildLearningCenter);
    });
    this.data.merge(merge);
  };

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  };

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  forPotentialChild(potentialChild) {
    return _.filter(this.all, ['potential_child_id', potentialChild.id]);
  }

  forLearningCenter(learningCenter) {
    return _.filter(this.all, ['learning_center_id', learningCenter.id]);
  }

  forParentInLearningCenter(parent, learningCenter) {
    return _.filter(
      this.all,
      connection =>
        connection.learning_center_id === learningCenter.id &&
        connection.potentialChild &&
        connection.potentialChild.parent_id === parent.id,
    );
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions
        .v2RawGet('/users/me/potential_child_learning_centers')
        .then(response => {
          this.addMany(response.data);
          this.requests.set('all', 'complete');
        });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(PotentialChildLearningCenterStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchAllCompleted: computed,
  refetch: action,
});

export class PotentialChildLearningCenter {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.potential_child_id = data.potential_child_id;
    this.learning_center_id = data.learning_center_id;
    this.proposed_username = data.proposed_username;
    this.proposed_email = data.proposed_email;
    this.status = data.status;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
  }

  get potentialChild() {
    return this._root.PotentialChildStore.find(this.potential_child_id);
  }

  get learningCenter() {
    return this._root.LearningCenterStore.find(this.learning_center_id);
  }

  get pending() {
    return this.status === 'requested';
  }

  get approved() {
    return this.status === 'approved';
  }
}
