import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';
import imagePath from 'utils/imagePath';

export default class TeamStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const team = new Team(data, this.root);
    this.data.set(team.id, team);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const team = new Team(data, this.root);
      merge.set(team.id, team);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('teams').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    this.reset();
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  get length() {
    return this.data.size;
  }

  forOrganization(orgId) {
    return Array.from(this.data.values()).filter(team => team.organization_id === orgId).filter(team => !team.archived);
  }

  archivedForOrganization(orgId) {
    return this.all.filter(team => team.archived);
  }
}

decorate(TeamStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
  length: computed
});

export class Team {
  constructor(data, root) {
    this._root = root;
    this.archived = data.archived;
    this.container_id = data.container_id;
    this.created_at = data.created_at;
    this.id = data.id;
    this.image_content_type = data.image_content_type;
    this.image_file_name = data.image_file_name;
    this.image_file_size = data.image_file_size;
    this.image_updated_at = data.image_updated_at;
    this.index = data.index;
    this.mission = data.mission;
    this.name = data.name;
    this.organization_id = data.organization_id;
    this.type = data.type;
    this.updated_at = data.updated_at;
    this.vision = data.vision;
  }

  get serialize() {
    return _.pick(this, [
      'archived',
      'container_id',
      'created_at',
      'image_file_name',
      'index',
      'mission',
      'name',
      'organization_id',
      'type',
      'updated_at',
      'vision'
    ]);
  }

  get organization() {
    return this._root.OrganizationStore.find(this.organization_id);
  }

  get imagePath() {
    return imagePath(this, 'teams');
  }

  get teamPositions() {
    return this._root.TeamPositionStore.forTeam(this.id)
      .sort(position => position.position === 'leader' ? -1 : 1);
  }

  get users() {
    return this.teamPositions.map(position => position.user);
  }

  get userIds() {
    return this.users.map(user => user && user.id).sort();
  }

  get leaders() {
    return this._root.TeamPositionStore.leadersFor(this.id);
  }

  get leaderIds() {
    return this.leaders.map(position => position && position.user_id).sort();
  }

  isLeader(userId) {
    return _.includes(this.leaderIds, userId);
  }

  get coachings() {
    return this._root.CoachingStore.forTeam(this.id);
  }

  get groups() {
    return this._root.TeamGroupStore.forTeam(this.id);
  }

  get activitiesCompletedCount() {
    return this._root.TeamActivitiesCompletedStore.find(this.id);
  }
}
