import { observable, action, decorate } from 'mobx';

class SpinnerStore {
  active = false;

  on() {
    this.active = true;
  }

  off() {
    this.active = false;
  }
}

decorate(SpinnerStore, {
  active: observable,
  on: action.bound,
  off: action.bound
});

export default new SpinnerStore();
