import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import moment from 'moment';
import apiActions from 'api/actions';

export default class ActivityInstanceStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const activityInstance = new ActivityInstance(data, this.root);
    this.data.set(activityInstance.id, activityInstance);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const activityInstance = new ActivityInstance(data, this.root);
      merge.set(activityInstance.id, activityInstance);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('activity_instances').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (this.requests.get(id) === undefined) {
      this.requests.set(id, 'pending');
      return apiActions.get('activity_instances', id).then((response) => {
        this.add(response.data);
        this.requests.set(id, 'complete');
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  fetchByCompetitionTeam(teamId) {
    const requestKey = `competitionTeam:${teamId}`;
    if (this.requests.get(requestKey) === undefined) {
      this.requests.set(requestKey, 'pending');
      return apiActions.index('activity_instances', { competition_team_id: teamId }).then((response) => {
        this.addMany(response.data);
        this.requests.set(requestKey, 'complete');
        return this.instancesForCompetitionTeam(teamId);
      });
    };
    return Promise.resolve(this.instancesForCompetitionTeam(teamId));
  }

  refetch() {
    const competitionTeamKey = RegExp('competitionTeam:*');
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      if (competitionTeamKey.test(key)) {
        return this.fetchByCompetitionTeam(key.split(':')[1]);
      }
      return this.fetchById(key);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  fetchByIdCompleted(id) {
    return this.requests.get(id) === 'complete';
  }

  instancesForCompetitionTeam(teamId) {
    const id = parseInt(teamId, 10);
    return Array.from(this.data.values()).filter((instance) => {
      return instance.competition_team_id === id;
    });
  }

  // TODO Does this catch instances that aren't the user's?
  // This takes the user's `machine_name`.
  completedInstancesFor(userName) {
    return Array.from(this.data.values()).filter(instance => {
      const state = instance.state;
      const activity = instance.activity;
      if (!state) return false;
      if (!state.joined.includes(userName)) return false;
      if (instance.competition_team_id) return false;

      if (!activity) return false;
      if (activity.preferences.evaluation) {
        return state.evaluation[userName] && state.evaluation[userName].completed;
      }
      if (activity.preferences.performance) {
        return state.performance.completed;
      }
      if (activity.preferences.group_staging) {
        return state.group_staging.completed;
      }
      if (activity.preferences.individual_staging) {
        return Object.values(state.individual_staging).every((ind) => ind.completed);
      }
      if (Object.keys(activity.preferences).length === 0) {
        return state.evaluation[userName] && state.evaluation[userName].completed;
      }
      return false;
    });
  }

  createdBy(userId) {
    return Array.from(this.data.values()).filter(instance => (
      instance.assigned && instance.created_by_id === userId
    ));
  }

  completedInstancesCreatedBy(userId) {
    return Array.from(this.data.values()).filter(instance => (
      instance.completed &&
        instance.created_by_id === userId &&
        instance.assigned
    ));
  }

  completedNonCompetitionInstancesCreatedBy(userId) {
    return this.completedInstancesCreatedBy(userId).filter(instance => !instance.competition_team_id);
  }

  incompleteNonCompetitionInstancesCreatedBy(userId) {
    return this.createdBy(userId).filter(instance => (
      !instance.completed && !instance.competition_team_id
    ));
  }

  forOrganization(orgId) {
    return Array.from(this.data.values()).filter(instance => instance.organization_id === orgId);
  }

  completedForOrganization(orgId) {
    return this.forOrganization(orgId).filter(instance => instance.completed && !instance.manually_completed_at);
  }

  completedAssignmentsFor(userId) {
    return Array.from(this.data.values()).filter(instance => (
      instance.completed &&
      !instance.manually_completed_at &&
      instance.created_by_id === userId &&
      (instance.assigned || instance.competition_team_id)
    ));
  }
}

decorate(ActivityInstanceStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchById: action,
  fetchByCompetitionTeam: action,
  refetch: action,
  fetchAllCompleted: computed
});

export class ActivityInstance {
  constructor(data, root) {
    this._root = root;
    this.active = data.active;
    this.activity_id = data.activity_id;
    this.assigned = data.assigned;
    this.competition_team_id = data.competition_team_id;
    this.completed = data.completed;
    this.completed_at = data.completed_at;
    // NOTE, REFACTOR: This is the same as the completed_at field.
    this.completed_ts = data.completed_ts;
    this.created_at = data.created_at;
    this.created_by_id = data.created_by_id;
    this.group_staging_feeback = data.group_staging_feedback;
    this.id = data.id;
    this.instance_code = data.instance_code;
    this.manually_completed_at = data.manually_completed_at;
    this.name = data.name;
    this.organization_id = data.organization_id;
    this.performance_feedback = data.performance_feedback;
    this.relevance = data.relevance;
    this.required = data.required;
    this.started_at = data.started_at;
    this.total_points = data.total_points;
    // NOTE, REFACTOR: This is the creation timestamp.
    this.ts = data.ts;
    this.user_is_creator = data.user_is_creator;
  }

  get state() {
    return this._root.ActivityStateStore.forActivityInstance(this.id);
  }

  get gameLogEntries() {
    return this._root.GameLogEntryStore.entriesForActivityInstance(this.id);
  }

  get activity() {
    return this._root.ActivityStore.find(this.activity_id);
  }

  get activityExecutions() {
    return this._root.ActivityExecutionStore.executionsForActivityInstance(this.id);
  }

  get users() {
    return this.activityExecutions.map((execution) => execution.user);
  }

  get enabledUsers() {
    return this.activityExecutions
      .filter(execution => !execution.disabled)
      .map(execution => execution.user);
  }

  get participatingUsers() {
    if (this.competition_team_id && this.competitionTeam) {
      return this.competitionTeam.activeUsers;
    }
    return this.enabledUsers;
  }

  get joinedUsers() {
    return this.users.filter(user => this.state.joined.includes(user.machine_name));
  }

  get creator() {
    return this._root.UserStore.find(this.created_by_id);
  }

  get competitionTeam() {
    return this._root.CompetitionTeamStore.find(this.competition_team_id);
  }

  get competition() {
    return this._root.CompetitionStore.find(this.competitionTeam.competition_id);
  }

  get completionDate() {
    if (this.completed_at) return this.completed_at;
    if (this.manually_completed_at) return this.manually_completed_at;
    if (!this.activity) return this.state.updated_at;
    if (!this.state) return false;
    const preferences = this.activity.calculatedPreferences;
    if (preferences.evaluation && this.state.evaluationComplete) return this.state.updated_at;

    if (!preferences.evaluation && preferences.performance && this.state.performanceComplete) return this.state.updated_at;

    if (!preferences.evaluation && !preferences.performance && preferences.group_staging && this.state.groupStagingComplete) return this.state.updated_at;

    if (!preferences.evaluation && !preferences.performance && !preferences.group_staging && preferences.individual_staging && this.state.individualStagingComplete) return this.state.updated_at;

    return false;
  }

  get onlyEvaluationIncomplete() {
    const preferences = this.activity.calculatedPreferences;
    return (preferences.performance && this.state.performanceComplete) ||
      (!preferences.performance && preferences.group_staging && this.state.groupStagingComplete);
  }

  get totalPoints() {
    return this.state && this.state.total_team_points ? this.state.total_team_points : 0;
  }

  trackFinalScore(userId) {
    /*
     Unfortunately, the current calculation of 'total team points' is done in a way
     that takes all individual scores and divides them by the number of participants.

     I wanted to be specific, since it would determine pass/fail on the track, and select
     the user's individual score specifically
    */

    const { calculatedPreferences: preferences } = this.activity;

    let total = 0;

    if (
      preferences &&
      preferences.individual_staging &&
      this.state.individual_scores &&
      this.state.individual_scores[userId] &&
      this.state.individual_scores[userId].score
    ) {
      total += this.state.individual_scores[userId].score;
    }

    if (
      preferences &&
      preferences.group_staging &&
      this.state.group_staging &&
      this.state.group_staging.score
    ) {
      total += this.state.group_staging.score;
    }

    if (preferences && preferences.performance && this.state.performance) {
      total += this.state.performance.score;
    }

    return total;
  }

  get levels() {
    return _.compact([
      this.activity.preferences.individual_staging && ({
        name: 'Do It Solo',
        key: 'individual_staging',
        individual: true,
        questions: this.activity.staging.steps.length,
        minutes: this.activity.staging.individual_time / 60,
      }),
      this.activity.preferences.group_staging && ({
        name: 'Discuss It',
        key: 'group_staging',
        questions: this.activity.staging.steps.length,
        minutes: this.activity.staging.group_time / 60,
      }),
      this.activity.preferences.performance && ({
        name: 'Deliver It',
        key: 'performance',
        questions: this.activity.steps.length,
        minutes: this.activity.time / 60,
      }),
      this.activity.preferences.evaluation && ({
        name: 'Peer Evaluation',
        key: 'evaluation',
      }),
    ]);
  }

  levelDetailsFor(user) {
    if (!this.isParticipant(user.id) && user.id === this.created_by_id) {
      return _.compact([
        this.activity.preferences.individual_staging && ({
          label: 'Do It Solo',
          key: 'individual_staging',
          individual: true,
          questions: this.activity.staging.steps.length,
          minutes: this.activity.staging.individual_time / 60,
          complete: this.state.individualStagingComplete,
          unlocked: Object.keys(this.state.individual_staging).length > 0,
          assigner: true,
        }),
        this.activity.preferences.group_staging && ({
          label: 'Discuss It',
          key: 'group_staging',
          questions: this.activity.staging.steps.length,
          minutes: this.activity.staging.group_time / 60,
          complete: this.state.groupStagingComplete,
          pointsEarned: this.state.group_staging.score,
          pointsTotal: this.activity.totalGroupStagingPoints,
          unlocked: _.get(this.state.group_staging, 'started', false),
          assigner: true,
        }),
        this.activity.preferences.performance && ({
          label: 'Deliver It',
          key: 'performance',
          questions: this.activity.steps.length,
          minutes: this.activity.time / 60,
          complete: this.state.performanceComplete,
          pointsEarned: this.state.performance.score,
          pointsTotal: this.activity.totalPerformancePoints,
          unlocked: _.get(this.state.performance, 'started', false),
          assigner: true,
        }),
        this.activity.preferences.evaluation && ({
          label: 'Peer Evaluation',
          key: 'evaluation',
          complete: this.state.evaluationComplete,
          assigner: true,
        }),
      ]);
    }
    return _.compact([
      this.activity.preferences.individual_staging && ({
        label: 'Do It Solo',
        key: 'individual_staging',
        individual: true,
        questions: this.activity.staging.steps.length,
        minutes: this.activity.staging.individual_time / 60,
        unlocked: _.includes(this.state.joined, user.machine_name),
        complete: this.state.individual_staging[user.machine_name] && this.state.individual_staging[user.machine_name].completed,
      }),
      this.activity.preferences.group_staging && ({
        label: 'Discuss It',
        key: 'group_staging',
        questions: this.activity.staging.steps.length,
        minutes: this.activity.staging.group_time / 60,
        unlocked: (this.activity.preferences.individual_staging && this.state.individual_staging[user.machine_name] && this.state.individual_staging[user.machine_name].completed) || (!this.activity.preferences.individual_staging && _.includes(this.state.joined, user.machine_name)),
        complete: this.state.groupStagingComplete,
        pointsEarned: this.state.group_staging.score,
        pointsTotal: this.activity.totalGroupStagingPoints,
      }),
      this.activity.preferences.performance && ({
        label: 'Deliver It',
        key: 'performance',
        questions: this.activity.steps.length,
        minutes: this.activity.time / 60,
        unlocked: (this.activity.preferences.group_staging && this.state.group_staging.completed) ||
          (!this.activity.preferences.group_staging && this.activity.preferences.individual_staging && this.state.individual_staging[user.machine_name].completed) ||
          (!this.activity.preferences.individual_staging && !this.activity.preferences.group_staging),
        complete: this.state.performanceComplete,
        pointsEarned: this.state.performance.score,
        pointsTotal: this.activity.totalPerformancePoints,
      }),
      this.activity.preferences.evaluation && ({
        label: 'Peer Evaluation',
        key: 'evaluation',
        complete: _.get(this.state.evaluation, [user.machine_name, 'completed'], false),
        unlocked: (this.activity.preferences.performance && this.state.performance.completed) ||
          (!this.activity.preferences.performance && this.activity.preferences.group_staging && this.state.group_staging.completed) ||
          (!this.activity.preferences.performance && !this.activity.preferences.group_staging && this.activity.preferences.individual_staging && this.state.individual_staging[user.machine_name].completed)
      })
    ]);
  }

  hasJoined(userMachineName) {
    return this.state && this.state.joined && _.includes(this.state.joined, userMachineName);
  }

  isParticipant(userId) {
    return _.includes(this.activityExecutions.map(exec => exec.user_id), userId);
  }

  joinedAt(userId) {
    const entry = _.find(this.gameLogEntries, { user_id: userId, category: 'joined_at' });
    if (entry) {
      return moment.utc(entry.created_at).local();
    }
    return false;
  }
}
