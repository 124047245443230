import React, { Component, lazy, Suspense } from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { stripeApiKey } from 'config/stripe-api-key';
import { withRouter } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { withCookies } from 'react-cookie';
import Intercom from 'react-intercom';

// Build an icon library so we only give the user the ones we use.
import registerFaIcons from './registerFaIcons';
import { intercomId } from 'config/apiUrl';
import ErrorBoundary from 'components/shared/error-boundary';
import actions from 'api/actions';
import LoadingSpinner from 'components/shared/loading-spinner';
import { tokenExpired } from 'utils/token';

const Main = lazy(() => import('components/containers/main'));
const Unauthenticated = lazy(() => import('components/containers/unauthenticated'));

registerFaIcons();

const App = inject('UserStore', 'routing', 'sessionStore')(observer(
  class App extends Component {
    constructor(props) {
      super(props);
      this.state = {
        stripe: null
      };
    }

    componentWillMount(){
      const { cookies } = this.props;
      this.getFreshAuthTokens()
        .then(({ token, refresh }) => {
          const user = cookies.get('currentUser');
          const org = cookies.get('org');
          const parent = cookies.get('parent');
          const isLoggedIn = this.props.sessionStore.loggedIn;
          if (user && !isLoggedIn) {
            this.props.sessionStore.login({ org, token, user, parent, refresh });
          }
        });
    }

    componentDidMount() {
      const { subscribe } = this.props.routing.history;

      this.unsubscribeFromStore = subscribe(() => {
        window.scrollTo(0, 0);
        actions.track(this.props.routing.location.pathname);
      });

      /*
        This is to help alleivate some of the issues loading stripe synchronously.

        Previously the stripe provider was having issues in rare circumstances when the script hadn't loaded yet.

        This strategy guarantees the stripe script loads before the form renders.
      */
      /* eslint-disable react/no-did-mount-set-state */
      if (window.Stripe) {
        this.setState({ stripe: window.Stripe(stripeApiKey) });
      } else {
        document.querySelector('#stripe-js').addEventListener('load', () => {
          this.setState({ stripe: window.Stripe(stripeApiKey) });
        });
      }
      /* eslint-enable react/no-did-mount-set-state */
    }

    componentWillUnmount() {
      this.unsubscribeFromStore();
    }

    getFreshAuthTokens() {
      const { cookies } = this.props;
      const token = cookies.get('token');
      const refresh = cookies.get('refresh');
      if (token && tokenExpired(token) && refresh) {
        return actions.refreshAuthToken(refresh);
      }
      return Promise.resolve({ token, refresh });
    }

    render() {
      const isLoggedIn = this.props.sessionStore.loggedIn;
      let user;
      if (isLoggedIn) {
        const userId = this.props.sessionStore.currentUser.id;
        const userFromStore = this.props.UserStore.find(this.props.sessionStore.currentUser.id);
        user = {
          user_id: userId,
          email: userFromStore ? userFromStore.email : '',
          name: userFromStore ? userFromStore.name : ''
        };
      }
      const RenderElement = isLoggedIn ? Main : Unauthenticated;

      return (
        <div>
          <ErrorBoundary>
            <Intercom appID={intercomId} {...user} location={this.props.routing.location.pathname} />
          </ErrorBoundary>
          <StripeProvider stripe={this.state.stripe}>
            <Suspense fallback={<LoadingSpinner />}>
              <RenderElement />
            </Suspense>
          </StripeProvider>
        </div>
      );
    }
  }
));

export default withCookies(withRouter(App));
