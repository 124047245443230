import { observable, action, computed, decorate } from 'mobx';
import apiActions from 'api/actions';

export class JournalEntry {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.text = data.text;
    this.skill_id = data.skill_id;
    this.power_skill_level_id = data.power_skill_level_id;
    this.inserted_at = data.inserted_at;
    this.updated_at = data.updated_at;
  }

  get skill() {
    return this._root.SkillStore.find(this.skill_id);
  }
}

export default class JournalEntryStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = data => {
    const journalEntry = new JournalEntry(data, this.root);
    this.data.set(journalEntry.id, journalEntry);
  }

  addMany = datas => {
    const merge = new Map();
    datas.forEach(data => {
      const journalEntry = new JournalEntry(data, this.root);
      merge.set(journalEntry.id, journalEntry);
    });
    this.data.merge(merge);
  }

  remove = id => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.rawGet('academics/journal').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  forSkill(skillId) {
    return this.all.filter(journalEntry => journalEntry.skill_id === skillId);
  }

  forPowerSkillLevel(levelId) {
    return this.all.filter(journalEntry => journalEntry.power_skill_level_id === levelId)
  }
}

decorate(JournalEntryStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});
