import {
  getEvents,
  hasNotRunForCurrentUrl,
  scopeToCurrentUrl,
  logForReportCard
} from './utility-methods';

const eventList = {
  TIMER_START: {
    header: "Heads up!",
    message: "The timer has started!",
    runnable() { return true; },
    afterRun: [],
    icon: 'warning'
  },
  WORKROOM_ACTIVITY_COLLABORATION: {
    message: "Heads up! You've entered a shared room. Work with your teammates to get through the activity. COMMUNICATE! If you're unsure, ask your team members or your teacher.",
    runnable: hasNotRunForCurrentUrl,
    afterRun: [scopeToCurrentUrl],
    icon: 'info'
  },
  TALKBACK_NO_ANSWER: {
    message: "This activity won't be completed on its own! Collaboration on this activity means all your team has to 'submit' (in green) to the same answer choice for it to be scored.",
    skillsAffected: ["Self Management"],
    interventionReportName: "Took a long time to provide an answer",
    humanFormat: "Communication Opportunity: Collectively took a long time to respond to card with their team",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_SUBMIT_NO_REASONING: {
    message: "Without explaining your answer at all, your teammates don't get any help and...you may not really understand why it's the answer!",
    skillsAffected: ["Responsible Decision Making", "Social Awareness"],
    interventionReportName: "Did not provide a reasoning with their answer",
    humanFormat: "Critical Thinking Opportunity: Submitted no reasoning for their submission",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_SHORT_REASONING: {
    message: "Your rationale is a bit short. Boosting your rationale means you really understand why you answered and will help you later.",
    skillsAffected: ["Responsible Decision Making", "Social Awareness"],
    interventionReportName: "Provided a short reasoning with their answer",
    humanFormat: "Critical Thinking Opportunity: Submitted a short reasoning for their submission",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_CHALLENGE: {
    message: "You've challenged an answer choice at least one of your teammates thinks is the right answer. Great chance to resolve this conflict by talking it out!",
    skillsAffected: ["Relationship Skills", "Social Awareness"],
    interventionReportName: "Challenged an answer choice that one of their teammates thinks is the right answer",
    humanFormat: "Conflict Resolution Opportunity: Challenged an answer that one or more of their teamates submitted to",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_LACKING_STUDENT_VOICE: {
    interventionReportName: "Submitted an incorrect answer in a group level when they answered correctly in the individual level",
    humanFormat: "Submitted an incorrect answer in a group level when they answered correctly in the individual level",
    runnable() { return false; }
  },
  TALKBACK_SUBMIT: {
    message: "You think the right answer is one at least one of your teammates thinks is the wrong answer choice. Great chance to resolve this conflict by talking it out!",
    skillsAffected: ["Relationship Skills", "Social Awareness"],
    interventionReportName: "Submitted an answer choice that one of their teammates thinks is the wrong answer",
    humanFormat: "Conflict Resolution Opportunity: Submitted to an answer that one or more of their teamates challenged",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_DIFFERENT_ANSWER_SUBMIT: {
    message: "It looks like a teammate has selected a different answer than you. Great chance to resolve this conflict by talking it out!",
    skillsAffected: ["Relationship Skills", "Social Awareness"],
    interventionReportName: "Submitted to a different answer than one of their teammates",
    humanFormat: "Conflict Resolution Opportunity: Submitted to an answer when one of their teammates submitted to a different answer",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_IMPULSIVE_ANSWER: {
    message: "That was a fast answer! Could be good! But, always check your answer to be sure it's what you want to contribute.",
    skillsAffected: ["Self Management"],
    interventionReportName: "Answered the question very quickly",
    humanFormat: "Impulse Control Opportunity: Answered impulsively",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_STRESS_MANAGEMENT_ORG: {
    message: "Time is winding down, but it's a good chance for you to be stronger. A great way to handle stress is to stay calm and focus only on solving your problem and choosing an answer choice. You've got this!",
    skillsAffected: ["Self Management"],
    interventionReportName: "Took a while to answer the question",
    humanFormat: "Stress Management Opportunity: Took a while to submit an answer or challenge",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_STRESS_MANAGEMENT_TIME: {
    message: "Time is winding down, but it's a good chance for you to be stronger. A great way to handle stress is to stay calm and focus only on solving your problem and choosing an answer choice. You've got this!",
    skillsAffected: ["Self Management"],
    interventionReportName: "Took a while to submit an answer or challenge",
    humanFormat: "Stress Management Opportunity: Took a while to submit an answer or challenge",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_PROBLEM_SOLVING: {
    humanFormat: "Showed problem solving ability",
    interventionReportName: "Showed problem solving ability",
    runnable() { return false; }
  },
  TALKBACK_LAST_ANSWER_SLOW: {
    message: "This is a message about stress management and organization!",
    skillsAffected: ["Self Management", "Self Awareness"],
    interventionReportName: "Was the last to answer the question",
    humanFormat: "Stress Management Opportunity: Was the last to answer and took most of the time to submit that answer",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_LAST_ANSWER: {
    interventionReportName: "Answered the question last on their team",
    humanFormat: "Stress Management Opportunity: Was the last to answer",
    runnable() { return false; }
  },
  TALKBACK_FIRST_ANSWER: {
    interventionReportName: "Answered the question first on their team",
    humanFormat: "Impulse Control Opportunity: Was the first to answer",
    runnable() { return false; }
  },
  WORKROOM_WRONG_ANSWER: {
    message: "You all agreed to the wrong answer, but KEEP AT IT so you can get the most amount of points available.",
    skillsAffected: ["Responsible Decision Making"],
    interventionReportName: "Collectively got an answer wrong with their teammates",
    humanFormat: "Collectively got the answer wrong with their team",
    afterRun: [logForReportCard],
    runnable() { return true; }
  },
  TALKBACK_LIKELY_GOOD_REASONING: {
    interventionReportName: "Likely provided a good reasoning for their answer",
    humanFormat: "Showed critical thinking",
    runnable() { return false; }
  },
  TALKBACK_LIKELY_BAD_ANSWER: {
    interventionReportName: "Likely provided an answer that doesn't help their team.",
    humanFormat: "Added an answer that doesn't help their team",
    runnable() { return false; }
  }
};

export default eventList;
