import { observable, action, computed, decorate } from 'mobx';
import apiActions from '../../api/actions';

export default class CoachingStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const coaching = new Coaching(data, this.root);
    this.data.set(coaching.id, coaching);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const coaching = new Coaching(data, this.root);
      merge.set(coaching.id, coaching);
    });
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  find(id) {
    return this.data.get(id);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.index('coachings').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(keys.map((key) => {
      return this.fetchByActivityInstance(key.split(':')[1]);
    }));
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }

  // Get an array of coachings in which this user is the coached user.
  coachingsForUser(userId) {
    userId = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(coaching => (
      coaching.coached_id === userId
    ));
  }

  // Get an arry of coachings in which this user is the coach.
  coachedForUser(userId) {
    userId = parseInt(userId, 10);
    return Array.from(this.data.values()).filter(coaching => (
      coaching.coach_id === userId
    ));
  }

  forTeam(teamId) {
    teamId = parseInt(teamId, 10);
    return Array.from(this.data.values()).filter(coaching => (
      coaching.team_id === teamId
    ));
  }

  coachingsForUserAndTeam(userId, teamId) {
    userId = parseInt(userId, 10);
    teamId = parseInt(teamId, 10);
    return Array.from(this.data.values()).filter(coaching => (
      coaching.coached_id === userId && coaching.team_id === teamId
    ));
  }

  coachingsForCoachAndCoached(coachId, coachedId) {
    coachId = parseInt(coachId, 10);
    coachedId = parseInt(coachedId, 10);

    return Array.from(this.data.values()).filter(coaching => (
      coaching.coach_id === coachId && coaching.coached_id === coachedId
    ));
  }
}

decorate(CoachingStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed
});

export class Coaching {
  constructor(data, root) {
    this._root = root;
    this.coach_id = data.coach_id;
    this.coached_id = data.coached_id;
    this.created_at = data.created_at;
    this.id = data.id;
    this.team_id = data.team_id;
    this.updated_at = data.updated_at;
  }

  get team() {
    return this._root.TeamStore.find(this.team_id);
  }

  get coach() {
    return this._root.UserStore.find(this.coach_id);
  }

  get coached() {
    return this._root.UserStore.find(this.coached_id);
  }
}
