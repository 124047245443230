import { observable, action, computed, decorate } from "mobx";
import _ from "lodash";
import moment from "moment";
import apiActions from "api/actions";

function getInitialQueryData() {
  const start = moment().add(-1, "months");
  const end = moment().add(2, "months");
  const range = moment.range(start, end);

  return Array.from(range.by("month")).map((month) => ({
    params: { year: month.format("YYYY"), month: month.format("MM") },
    key: month.format("YYYY-MM"),
  }));
}

export class UlcEvent {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.date = data.date;

    this.end_time = data.end_time;
    this.start_time = data.start_time;
    this.status = data.status;
    this.location = data.location;
    this.learning_center_id = data.learning_center_id;
    this.parent_id = data.parent && data.parent.id;
    this.parent_email = data.parent && data.parent.email;
    this.parent_first_name = data.parent && data.parent.first_name;
    this.parent_last_name = data.parent && data.parent.last_name;
    this.user_ids = data.user_ids || [];
    this.potential_children_ids = data.potential_children_ids || [];
  }

  get parent() {
    return this._root.ParentStore.find(this.parent_id);
  }

  get users() {
    const potentialChildren = this.potential_children_ids.map((id) =>
      this._root.PotentialChildStore.find(id)
    );
    const users = this.user_ids.map((id) => this._root.UserStore.find(id));
    return _.compact(users.concat(potentialChildren));
  }
  get localStart() {
    return this.startMoment.local();
  }
  get startMoment() {
    return moment.utc(`${this.date} ${this.start_time}`, "YYYY-MM-DD hh:mm");
  }
  get localEnd() {
    return this.endMoment.local();
  }
  get endMoment() {
    const naiveUtcEnd = moment.utc(
      `${this.date} ${this.end_time}`,
      "YYYY-MM-DD hh:mm"
    );
    if (this.startMoment.isAfter(naiveUtcEnd)) {
      // End time is next UTC date, so we need to shift from there.
      return naiveUtcEnd.add(1, "day").local();
    }
    // End time is same UTC date, so it doesn't matter.
    return naiveUtcEnd.local();
  }
  get isInPast() {
    return this.endMoment.isBefore(moment.utc());
  }
  get requiresAction() {
    return (
      this.status === "pending" ||
      (this.status === "scheduled" && this.localEnd.isBefore(moment()))
    );
  }
  get creditsUsed() {
    if (this.status === "cancelled") {
      return 0;
    }
    return moment.duration(this.endMoment.diff(this.startMoment)).as("hours");
  }
}

export default class UlcEventStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const track = new UlcEvent(data, this.root);
    this.data.set(track.id, track);
  };

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach((data) => {
      const ulcEvent = new UlcEvent(data, this.root);
      merge.set(ulcEvent.id, ulcEvent);
    });
    this.data.merge(merge);
  };

  remove = (id) => {
    this.data.delete(id);
  };

  find(id) {
    return this.data.get(id);
  }

  get all() {
    return Array.from(this.data.values());
  }

  get groupedByStatusAll() {
    return _.groupBy(Array.from(this.data.values()), "status");
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  };

  fetchInitial() {
    const queryData = getInitialQueryData();
    const requests = [];

    queryData.forEach(({ params, key }) => {
      if (this.requests.get(key) === undefined) {
        this.requests.set(key, "pending");
        requests.push(
          apiActions.index("events", params).then((response) => {
            this.addMany(response.data);
            this.requests.set(key, "complete");
          })
        );
      }
    });

    return Promise.all(requests);
  }

  fetchAll() {
    if (this.requests.get("all") === undefined) {
      this.requests.set("all", "pending");
      return apiActions.index("events").then((response) => {
        this.addMany(response.data);
        this.requests.set("all", "complete");
      });
    }
    return Promise.resolve();
  }

  fetchById(id) {
    if (
      this.requests.get("all") === undefined &&
      this.requests.get(id) === undefined
    ) {
      this.requests.set(id, "pending");
      return apiActions.get("events", id).then((response) => {
        this.add(response.data);
        this.requests.set(id, "complete");
        return this.find(id);
      });
    }
    return Promise.resolve(this.find(id));
  }

  refetch() {
    if (this.requests.get("all") !== undefined) {
      this.reset();
      return this.fetchAll();
    } else if (this.fetchInitialCompleted) {
      this.reset();
      return this.fetchInitial();
    }
    const keys = Array.from(this.requests.keys());
    this.reset();
    return Promise.all(
      keys.map((key) => {
        return this.fetchById(key);
      })
    );
  }

  get fetchInitialCompleted() {
    const queryData = getInitialQueryData();
    return _.every(
      queryData,
      ({ key }) => this.requests.get(key) === "complete"
    );
  }

  get fetchAllCompleted() {
    return this.requests.get("all") === "complete";
  }

  fetchByIdCompleted(id) {
    return this.fetchAllCompleted || this.requests.get(id) === "complete";
  }

  isFirstEvent(parentId) {
    return (
      Array.from(this.data.values()).filter(
        (event) => event.parent_id === parentId
      ).length === 1
    );
  }

  hasNoEvents(parentId) {
    return (
      Array.from(this.data.values()).filter(
        (event) => event.parent_id === parentId
      ).length === 0
    );
  }

  creditsUsedForParent(parentId) {
    return Array.from(this.data.values())
      .filter((event) => event.parent_id === parentId)
      .map((event) => event.creditsUsed)
      .reduce((acc, hours) => acc + hours, 0);
  }

  creditsUsedForParentInUlc(parentId, learningCenterId) {
    return Array.from(this.data.values())
      .filter(
        (event) =>
          event.parent_id === parentId &&
          event.learning_center_id === learningCenterId
      )
      .map((event) => event.creditsUsed)
      .reduce((acc, hours) => acc + hours, 0);
  }

  futureEventsForParent(parentId) {
    return Array.from(this.data.values())
      .filter((event) => event.parent_id === parentId)
      .filter((event) => !event.isInPast)
      .sort((a, b) => (a.startMoment.isBefore(b.startMoment) ? -1 : 1));
  }

  pastEventsForParent(parentId) {
    return Array.from(this.data.values())
      .filter((event) => event.parent_id === parentId)
      .filter((event) => event.isInPast)
      .sort((a, b) => (b.startMoment.isBefore(a.startMoment) ? -1 : 1));
  }

  futureEventsForChild(parentId, childUserId) {
    return this.futureEventsForParent(parentId).filter((event) =>
      _.includes(event.user_ids, childUserId)
    );
  }

  futureEventsForPotentialChild(parentId, potentialChildUserId) {
    return this.futureEventsForParent(parentId).filter((event) =>
      _.includes(event.potential_children_ids, potentialChildUserId)
    );
  }

  eventsForDate = (date) => {
    return this.all.filter((event) => event.localStart.isSame(date, "day"));
  };
}

decorate(UlcEventStore, {
  data: observable,
  requests: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  reset: action,
  fetchAll: action,
  fetchById: action,
  refetch: action,
  fetchAllCompleted: computed,
  groupedByStatusAll: computed,
});
