import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class AreaOfFocusStore {
  constructor(root) {
    this.root = root;
  }

  data = new Map();
  requests = new Map();

  add = (data) => {
    const areaOfFocus = new AreaOfFocus(data, this.root);
    this.data.set(areaOfFocus.id, areaOfFocus);
  }

  addMany = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const areaOfFocus = new AreaOfFocus(data, this.root);
      merge.set(areaOfFocus.id, areaOfFocus);
    })
    this.data.merge(merge);
  }

  remove = (id) => {
    if (id && this.data.has(id)) this.data.delete(id);
  }

  find(id) {
    return this.all.find(areaOfFocus => areaOfFocus.id === id);
  }

  findByName(name) {
    return this.all.find(areaOfFocus => areaOfFocus.name === name);
  }

  get all() {
    return Array.from(this.data.values());
  }

  get academic() {
    return this.all.filter(area => area.isAcademic);
  }

  get power() {
    return this.all.filter(area => area.isPower);
  }

  reset = () => {
    this.data.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.v2RawGet('areas_of_focus').then((response) => {
        this.addMany(response.data);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

decorate(AreaOfFocusStore, {
  data: observable,
  add: action,
  addMany: action,
  remove: action,
  all: computed,
  academic: computed,
  power: computed,
  reset: action,
  fetchAll: action,
  refetch: action,
  fetchAllCompleted: computed,
})

export class AreaOfFocus {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.type = data.type;
    this.name = data.name;
    this.description = data.description;
  }

  get isAcademic() {
    return this.type === 'academic';
  }

  get isPower() {
    return this.type === 'power';
  }
}

decorate(AreaOfFocus, {
  isAcademic: computed,
  isPower: computed,
});
