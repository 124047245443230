import { observable, action, computed, decorate } from 'mobx';
import _ from 'lodash';
import apiActions from 'api/actions';

export default class GradeStore {
  constructor(root) {
    this.root = root;
  }

  basicData = new Map();
  expandedData = new Map();
  requests = new Map();

  addBasic = (data) => {
    const grade = new Grade(data, this.root);
    this.basicData.set(grade.id, grade);
  }

  addExpanded = (data) => {
    const grade = new Grade(data, this.root);
    this.expandedData.set(grade.id, grade);
  }

  addManyBasic = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const grade = new Grade(data, this.root);
      merge.set(grade.id, grade);
    });
    this.basicData.merge(merge);
  }

  addManyExpanded = (datas) => {
    const merge = new Map();
    datas.forEach(data => {
      const grade = new Grade(data, this.root);
      merge.set(grade.id, grade);
    });
    this.expandedData.merge(merge);
  }

  remove = (id) => {
    if (id && this.basicData.has(id)) this.basic.delete(id);
    if (id && this.expandedData.has(id)) this.expanded.delete(id);
  }

  find(id) {
    return this.all.find(grade => grade.id === id);
  }

  get all() {
    return _.chain(this.basic)
            .concat(this.expanded)
            .uniqBy('id')
            .value();
  }

  get basic() {
    return _.sortBy(Array.from(this.basicData.values()), 'weight');
  }

  get expanded () {
    return Array.from(this.expandedData.values());
  }

  reset = () => {
    this.basicData.clear();
    this.expandedData.clear();
    this.requests.clear();
  }

  fetchAll() {
    if (this.requests.get('all') === undefined) {
      this.requests.set('all', 'pending');
      return apiActions.v2RawGet('grades').then((response) => {
        this.addManyBasic(response.data.basic);
        this.addManyExpanded(response.data.expanded);
        this.requests.set('all', 'complete');
      });
    }
    return Promise.resolve();
  }

  refetch() {
    if (this.requests.get('all') !== undefined) {
      this.reset();
      return this.fetchAll();
    }
    return Promise.resolve();
  }

  get fetchAllCompleted() {
    return this.requests.get('all') === 'complete';
  }
}

 decorate(GradeStore, {
   basicData: observable,
   expandedData: observable,
   addBasic: action,
   addExpanded: action,
   addManyBasic: action,
   addManyExpanded: action,
   remove: action,
   all: computed,
   basic: computed,
   expanded: computed,
   reset: action,
   fetchAll: action,
   refetch: action,
   fetchAllCompleted: computed,
 });

export class Grade {
  constructor(data, root) {
    this._root = root;
    this.id = data.id;
    this.display = data.display;
    this.weight = data.weight;
  }
}
