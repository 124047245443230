import ordinal from 'ordinal';

/**
 * See if this string is actually an integer in disguise!
 *
 * The value given must only contain an integer to return a true value.
 *
 * @param {string} value The string to test.
 * @returns {boolean}
 */
export function isIntegerString(value) {
  return /^-?\d+$/.test(value);
}

/**
 * Make this grade level presentable!
 *
 * Since grade levels can be integers disguised as strings, this helps us
 * properly format those ordinal ones.
 *
 * @param {string} gradeLevel The grade level to format.
 * @returns {string}
 */
export function formatGradeLevel(gradeLevel) {
  return isIntegerString(gradeLevel) ? `${ordinal(parseInt(gradeLevel, 10))} grade` : gradeLevel;
}
