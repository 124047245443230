import { observable, action, reaction, decorate } from 'mobx';
import swal from 'sweetalert';

class HelperEventStore {
  queue = [];
  currentAlert = {}

  constructor() {
    this.alertRunner = reaction(
      () => this.currentAlert,
      alert => {
        if (alert.alert) {
          swal({ ...this.currentAlert.alert })
            .then(confirm => {
              this.currentAlert.afterRun();
            });
        }
      }
    );
  }

  add(alertData) {
    if (Object.keys(this.currentAlert).length < 1) {
      this.currentAlert = alertData(this.pop.bind(this));
    } else {
      this.queue.push(alertData(this.pop.bind(this)));
    }
  }

  pop(timestamp) {
    if (this.queue.length === 0) {
      this.currentAlert = {};
    } else {
      this.currentAlert = this.queue.pop();
    }
  }
}

decorate(HelperEventStore, {
  queue: observable,
  currentAlert: observable,
  add: action,
  pop: action
});

export default new HelperEventStore();
